import {
  Alert,
  Button,
  Col,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import React, { useContext, useEffect, useRef } from "react";
import {
  Clipboard,
  Download,
  ExternalLink,
} from "react-feather";
import NotyfContext from "../../../contexts/NotyfContext";
import VNCLogo from "../../../assets/img/realVNC.png";
import HMIIcon from "../../../assets/img/hmi.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import UserDataManager from "../../Helper/UserDataManager";
import { VncScreen } from '@cap-on/cap-onvnc';

const RemoteHMISectionModal = ({ show, handleClose }) => {

  let { id } = useParams();
  const [activeHMISession, setActiveHMISession] = useState();
  const [needsApproval, setNeedsApproval] = useState();
  const [isLoadingHMIConnection, setIsLoadingHMIConnection] = useState(false)
  const [noResponseTimeout, setNoResponseTimeout] = useState(false)
  const { t, i18n } = useTranslation();
  const notyf = useContext(NotyfContext);
  const ref = useRef();

  useEffect(() => {

    if (show) {
      checkHMIStatus()
    }

  }, [show]);

  function checkHMIStatus() {
    axios
      .get("/assets/" + id + "/hmi_con")
      .then(function (response) {
        // handle success
        console.log(response.status, "/active HMI", response.data);
        setActiveHMISession(response.data);
      })
      .catch(function (error) {
        // handleError(error);
        setActiveHMISession();
        console.log(error);
      });
  }

  function openHMI(url) {

    let fullUrl = `com.realvnc.vncviewer.connect://${url}`

    // console.log("-> full url = ", fullUrl)
    open(fullUrl)
  }

  function open(url) {
    window.open(url, "_self");
  }

  function startHMIConnection(e) {
    e.preventDefault()

    setIsLoadingHMIConnection(true)
    setNoResponseTimeout(false)

    axios
      .post(`/assets/${id}/hmi_con`)
      .then(function (response) {
        // handle success
        console.log(response.status, `/assets/hmi_con`);

        // notyf.open({
        //   type: "success",
        //   message: t("Starting HMI session"),
        //   duration: "4500",
        //   ripple: false,
        //   dismissible: true,
        //   position: {
        //     x: "right",
        //     y: "bottom",
        //   },
        // })

        if (response.data?.approval_needed) {
          startPolling()
        } else {
          setActiveHMISession(response.data);
          setIsLoadingHMIConnection(false)
        }

        // openHMI(response.data.vnc_url);

      })
      .catch(function (error) {
        // handle error
        console.log(error?.response?.data?.message || error?.response?.data?.detail || "Error starting HMI session");
        setIsLoadingHMIConnection(false)

        notyf.open({
          type: "danger",
          message: t(error?.response?.data?.message || error?.response?.data?.detail || "Error starting HMI session"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
      });
  };

  function stopHMIConnection(e) {
    e.preventDefault()

    axios
      .delete(`/assets/${id}/hmi_con`)
      .then(function (response) {
        // handle success
        console.log(response.status, `/assets/hmi_con`);

        notyf.open({
          type: "success",
          message: t("Stopped HMI session"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        setActiveHMISession()

      })
      .catch(function (error) {
        // handle error
        console.log(error?.response?.data?.message);

        notyf.open({
          type: "danger",
          message: t(error?.response?.data?.message),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

      });
  };

  function startPolling() {
    let pollingInterval;
    let timeoutId;

    setNeedsApproval(true)

    // Function to stop polling
    function stopPolling() {
      if (pollingInterval) clearInterval(pollingInterval);
      if (timeoutId) clearTimeout(timeoutId);
      setNeedsApproval(false)
      setIsLoadingHMIConnection(false)
    }

    // Start a timeout to ensure polling stops after 60 seconds
    timeoutId = setTimeout(() => {
      stopPolling();
      console.log("Polling timeout: Approval not received within 60 seconds.");
      setNoResponseTimeout(true)
      // notyf.open({
      //   type: "warning",
      //   message: t("Approval not received within the timeout period."),
      //   duration: 4500,
      //   ripple: false,
      //   dismissible: true,
      //   position: {
      //     x: "right",
      //     y: "bottom",
      //   },
      // });
    }, 20000); // 30 seconds timeout

    // Start polling every second
    pollingInterval = setInterval(() => {
      axios
        .post(`/assets/${id}/hmi_con/approval_poll`)
        .then((response) => {
          console.log(response.status, "/approval_poll HMI", response.data);

          // Check if vnc_password is available
          if (response.data?.vnc_password) {
            setActiveHMISession(response.data);
            stopPolling(); // Stop polling when websocket_url is available
          }
        })
        .catch((error) => {
          console.error("Error during approval polling:", error);
          setIsLoadingHMIConnection(false)
          notyf.open({
            type: "danger",
            message: t("Error occurred while polling approval."),
            duration: 4500,
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          });
          stopPolling(); // Stop polling on error
        });
    }, 2000); // Polling interval: 1 second
  }

  // function copyToClipboard(text) {

  //   navigator.clipboard.writeText(text)

  //   notyf.open({
  //     type: "success",
  //     message: t("Copied Remote-HMI details"),
  //     duration: "4500",
  //     ripple: false,
  //     dismissible: true,
  //     position: {
  //       x: "right",
  //       y: "bottom",
  //     },
  //   })
  // };

  // const VNCViewer = ({ vncServerUrl }) => {
  //   useEffect(() => {
  //     const RFB = require('../../../components/caponVNC/src/noVNC/core/rfb').default;

  //     let rfb;
  //     const loadVNC = () => {
  //       try {
  //         rfb = new RFB(document.getElementById('vnc-container'), vncServerUrl, {
  //           credentials: {
  //           },
  //         });
  //         rfb.scaleViewport = true; // Optional: Scale the viewport

  //         rfb.addEventListener('connect', () => console.log('Connected to server'));
  //         rfb.addEventListener('disconnect', (e) => console.log('Disconnected:', e));
  //         rfb.addEventListener('securityfailure', (e) => console.error('Security failure:', e.detail));

  //       } catch (error) {
  //         console.error('Failed to initialize VNC connection:', error);
  //       }
  //     };

  //     loadVNC();

  //     return () => {
  //       if (rfb) {
  //         rfb.disconnect();
  //       }
  //     };
  //   }, [vncServerUrl]);

  //   return <div id="vnc-container" style={{ width: '300px', height: '100%' }} />;
  // };

  const LoadingView = ({ loadingText, subtext }) => {
    return (<div className="text-center mb-3">
      <br />
      <Spinner animation="border" variant="secondary" className="mb-4" />
      <div className="h3">{loadingText}</div>
      <div>{subtext}</div>
    </div>)
  }

  const vnc_password_string = activeHMISession?.vnc_password?.toString()

  return (
    <Modal size="xl" show={show} onHide={handleClose} >
      <Modal.Header>{t("Remote-HMI Access")}</Modal.Header>
      <Modal.Body className="m-3" style={{ background: "#f5f5f5", padding: "20px" }}>
        {/* <Row>
          <Col>
            <Alert variant="secondary">
              <div className="alert-message">
                For cyber-security reasons we strongly recommened avoiding a <strong>public Internet connection</strong> such as hotspots of public transport, etc.
                <br />Please only use secure connections.
              </div>
            </Alert>
          </Col>
        </Row> */}
        <Row>
          <Col md="12">
            <div className="text-center">

              {!activeHMISession && <Row className="mt-4 mb-4">
                <Col>
                  <img
                    src={HMIIcon}
                    alt="avatar"
                    width="auto"
                    height={"300"}
                  />
                </Col>
              </Row>}

              {isMobile
                ? <Col>
                  <div className="h5 mb-4">{t("The Remote-HMI is currently only available on the desktop version of the IoT-Hub.")}</div>
                </Col>
                : <>
                  <Row className="mt-0 mb-4">
                    <Col>
                      {noResponseTimeout && <Alert variant="secondary" className="mt-3">
                        <div className="alert-message">
                          {t("The operator did not respond in time or declined the connection.")}
                        </div>
                      </Alert>}

                      {isLoadingHMIConnection
                        ? <LoadingView
                          loadingText={needsApproval ? t("Requesting remote HMI access") : t("Starting Remote HMI")}
                          subtext={needsApproval ? t("An operator needs to accept your request.") : t("This will take a few seconds. Lean back!")}
                        />
                        : activeHMISession && activeHMISession?.username !== UserDataManager.getInstance().getUserData().email
                          ? <div className="h5 mb-4">{t("Someone else is currently monitoring the HMI. Please try again later.")}<br /> <br /> {UserDataManager.getInstance().isAdmin && `(Currently active: ${activeHMISession?.email})`}</div>
                          : activeHMISession
                            ? <Col>
                              <Alert variant="secondary" className="mt-3">
                                <div className="alert-message">
                                  {t("The Remote-HMI needs a few seconds to boot up. This may take up to 10 seconds once you see the black screen below.")}<br />
                                  <strong>{t("Active Remote-HMI session for ") + formatDistanceToNow(parseISO(activeHMISession?.to_be_closed_at), { locale: i18n.language === "de" ? de : null })}</strong>
                                  <br /><br />
                                  <Button
                                    onClick={e => {
                                      stopHMIConnection(e)
                                      handleClose(e)
                                    }}
                                    variant="outline-danger"
                                    className="shadow-sm ms-2">
                                    <Clipboard className="feather" /> {t("Stop connection")}
                                  </Button>
                                </div>
                              </Alert>
                            </Col>
                            : <Button
                              onClick={e => startHMIConnection(e)}
                              variant="outline-primary"
                              className="shadow-sm mt-3">
                              <Clipboard className="feather" /> {t("Initiate remote HMI connection")}
                            </Button>}
                    </Col>
                  </Row>

                  {/* {activeHMISession?.username === UserDataManager.getInstance().getUserData().email && <>
                    <hr />
                    <Row className="mt-4 mb-4">
                      <Col>
                        <img
                          src={VNCLogo}
                          alt="avatar"
                          width={"50"}
                          height={"50"}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                      <Col>
                        <Button
                          onClick={() => open(`https://www.realvnc.com/de/connect/download/viewer/windows/`)}
                          variant="outline-primary"
                          className="shadow-sm">
                          <Download className="feather" /> {t("Free download: RealVNC Viewer")}
                        </Button>
                        <Button
                          href={`com.realvnc.vncviewer.connect://${activeHMISession?.vnc_url}`}
                          variant="primary"
                          className="shadow-sm ms-2">
                          <ExternalLink className="feather" /> {t("Show HMI in RealVNC Viewer")}
                        </Button>
                      </Col>
                    </Row>
                  </>} */}
                </>
              }

              {activeHMISession && activeHMISession?.username === UserDataManager.getInstance().getUserData().email && <Row>
                <VncScreen
                  url={`${activeHMISession?.websocket_url}/websockify/?ws_token=${activeHMISession?.ws_token}`} // {'wss://100.101.159.121:5901/websockify'} "wss://gw.cap-on.de/ws/1/websockify" // ?ws_token=${activeHMISession?.id}
                  scaleViewport
                  qualityLevel={1}
                  compressionLevel={9}
                  rfbOptions={
                    needsApproval
                      ? {
                        credentials: {
                          password: vnc_password_string,
                        }
                      }
                      : null
                  }
                  showDotCursor
                  // viewOnly={false} // muss false sein
                  trueColor={false}
                  background="#000000"
                  onDisconnect={() => {
                    console.log("VNC connection failed or disconnected.");
                    setActiveHMISession();
                  }}
                  onSecurityFailure={() => {
                    console.error("Security failure occurred in VNC connection.");
                    setActiveHMISession();
                  }}
                  style={{
                    width: '75vw',
                    height: '75vh',
                  }}
                  ref={ref}
                />
              </Row>}
            </div>

            {/* <iframe title="Test" height={"1200"} width={"100%"} src={"http://www.realvnc.com/de/connect/download/combined/"}></iframe> */}
            {/* <VNCViewer vncServerUrl={"ws://3.126.218.17:5901/websockify"} /> */}

          </Col>
        </Row>
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={e => {
              activeHMISession && stopHMIConnection(e)
              handleClose(e)
            }} >
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  )
}

export default RemoteHMISectionModal;
