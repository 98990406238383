import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import axios from "axios";
import qs from "qs";
// import useAuth from "../../hooks/useAuth";
// import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserDataManager from "../../pages/Helper/UserDataManager";
import { getIAMBaseURL } from "../../config";

function refreshPage() {
  window.location.reload(false);
}

function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();

  const lastRoute = location.state?.from?.pathname;

  const { t } = useTranslation();

  function getErrorMessage(error) {
    switch (error.message) {
      case "Request failed with status code 400":
        return t("Incorrect credentials. Please try again.")
      case "Network Error":
        return t("We are currently working on the system. Please try again in a few minutes.")
      default:
        return error.message || "Something went wrong"
    }
  }

  const handleSignIn = async (values, { setErrors, setStatus, setSubmitting, navigate, lastRoute }) => {

    // if (!navigator.onLine) {
    //   setErrors({ submit: "No internet connection. Please check your network and try again." });
    //   setSubmitting(false);
    //   return;
    // }

    console.log("----> attempting signing");

    axios({
      method: "post",
      timeout: 5000,
      url: getIAMBaseURL() + "/login/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json",
      },
      params: {
        "add_user_info": true
      },
      data: qs.stringify({
        grant_type: "",
        username: values.email,
        password: values.password,
        scope: "",
        client_id: "",
        client_secret: "",
      }),
    })
      .then((response) => {

        console.log(response.status, "Logged in successfully", response.data);
        const token = response.data.access_token;
        localStorage.setItem('token', token);

        // const tenants = response.data.user_info?.tenants || [];
        // let tenantId = tenants.length > 0 ? tenants[0].id : null;

        // Check if a tenant named "main" exists
        // const mainTenant = tenants.find(tenant => tenant.name === "main");
        // if (mainTenant) {
        //   tenantId = mainTenant.id;
        // }

        // if (tenantId) {
        //   localStorage.setItem("tenant_id", tenantId);
        // }

        // If there is only one tenant available, automatically set this one.
        if (response.data.tenants?.length === 1) {
          localStorage.setItem("tenant_id", response.data?.tenants[0]?.id);
        }


        UserDataManager.getInstance().setUserData(response.data.user_info);

        if (response.data.user_info.status === "initial") {
          navigate("/confirm-sign-in", { state: { userData: response.data.user_info } });
          refreshPage();
          console.log("-> Info for TS (1)", lastRoute);
        } else {
          if (lastRoute && lastRoute !== "/sign-in" && lastRoute !== "/confirm-sign-in") {
            console.log("--> Info for TS (2)", lastRoute);
            navigate(lastRoute, { state: { initialLoad: true } });
            refreshPage();
          } else {
            console.log("--> Info for TS (3)", lastRoute);

            const baseUrl = response.data.user_info.webapp_base_url;

            if (baseUrl) {
              // Ensure the base URL doesn't already include 'https://'
              console.log("->", `https://${baseUrl}/dashboard`, baseUrl);
              window.location.href = `https://${baseUrl}/dashboard`;
              refreshPage();
            } else {
              navigate("/dashboard");
              refreshPage();
            }

          }
        }
      })
      .catch((error) => {
        let message;

        if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
          message = "The request took too long. Please check your internet connection and try again.";
        } else {
          message = getErrorMessage(error) || "Something went wrong";
        }

        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
        console.log("->", error);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Must be a valid email"))
          .max(255)
          .required(t("Email is required")),
        password: Yup.string().max(255).required(t("Password is required")),
      })}
      onSubmit={(values, actions) => handleSignIn(values, { ...actions, navigate, lastRoute })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder={t("Email")}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Password")}</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder={t("Password")}
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <div className="text-center mt-4">
            <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
              {t("Sign in")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
