import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Dropdown, Form, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Filter } from "react-feather";
import { useTranslation } from "react-i18next";
import { format, parseISO, isToday, formatDistanceToNow, isYesterday } from 'date-fns'
import { de, hi } from "date-fns/locale"
import ErrorHandler from "../Helper/ErrorHandler";
import AddUserModal from "./UserModal_Add";
import { getIAMBaseURL } from "../../config";
import Select from "react-select";

const Users = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const { t, i18n } = useTranslation();
    const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

    // When retrieving stored data.
    // let userRole = UserDataManager.getInstance().getUserRole();
    // console.log(userRole);

    function getStatus(status) {
        switch (status) {
            case "approved":
                return t("Approved")
            case "active":
                return t("Active")
            case "request":
                return t("Request")
            case "inactive":
                return t("Inactive")
            case "draft":
                return t("Draft")
            case "initial":
                return t("Initial")
            default:
                return t(status)
        }
    }

    useEffect(() => {
        axios
            .get(getIAMBaseURL() + "/users/", {
                params: {
                    limit: 150
                }
            })
            .then(function (response) {
                // handle success
                console.log(response.status, "/users", response.data)
                // setData(response.data.sort((a, b) => a.first_name > b.first_name))
                // setFilteredData(response.data.sort((a, b) => a.first_name > b.first_name))

                const users = response.data.sort((a, b) => new Date(b?.last_login) - new Date(a?.last_login));
                setData(users);

                // setFilteredData(users);
                // Apply filtering immediately after setting data
                const filteredUsers = users.filter(user => user.role !== "device");
                setFilteredData(filteredUsers); // Ensure devices are hidden initially


                const tenantMap = new Map();
                const roleMap = new Map();
                users.forEach(user => {
                    user.tenants.forEach(t => {
                        if (!tenantMap.has(t.id)) {
                            tenantMap.set(t.id, { value: t.id, label: t.name.toUpperCase() });
                        }
                    });
                    if (!roleMap.has(user.role)) {
                        roleMap.set(user.role, { value: user.role, label: user.role.toUpperCase() });
                    }
                });
                setTenants(Array.from(tenantMap.values()));
                setRoles(Array.from(roleMap.values()));
            })
            .catch(function (error) {
                // handle error
                setError({ showMessage: true, errorData: error })
                console.log(error);
            });
    }, []);

    const [tenants, setTenants] = useState([]);
    const [hideDevices, setHideDevices] = useState(true);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const filterByTenant = (selectedOption) => {
        setSelectedTenant(selectedOption);
        filterData(selectedOption, selectedRole, searchTerm, hideDevices);
    };

    const filterByRole = (selectedOption) => {
        setSelectedRole(selectedOption);
        filterData(selectedTenant, selectedOption, searchTerm, hideDevices);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        filterData(selectedTenant, selectedRole, e.target.value, hideDevices);
    };

    const handleHideDevices = (e) => {
        const checked = e.target.checked;
        setHideDevices(checked);
        filterData(selectedTenant, selectedRole, searchTerm, checked);
    };

    const filterData = (tenant, role, search, hideDevicesFlag) => {

        // console.log("---> Filtering", tenant, role, search, hideDevicesFlag);
        let filtered = data;
        if (tenant) {
            filtered = filtered.filter(user => user.tenants.some(t => t.id === tenant.value));
        }
        if (role) {
            filtered = filtered.filter(user => user.role === role.value);
        }
        if (search) {
            filtered = filtered.filter(user => `${user.first_name} ${user.last_name}`.toLowerCase().includes(search.toLowerCase()) || user.email.toLowerCase().includes(search.toLowerCase()));
        }
        if (hideDevicesFlag) {
            filtered = filtered.filter(user => user.role !== "device");
        }

        setFilteredData(filtered);
    };

    const renderTable = () => {
        return filteredData?.map(user => (
            <tr key={user.id} onClick={() => navigate("/users/" + user.id)} style={{ cursor: "pointer" }}>
                <td><span className={user.status === "active" ? "badge bg-success" : "badge bg-warning"}>{t(user.status)}</span></td>
                <td><img src={user.avatar_url || ""} className="center rounded-circle" alt="avatar" width={28} height={28} /></td>
                <td><strong>{user.first_name} {user.last_name}</strong></td>
                <td>{user?.position || "-"}</td>
                <td>{user.role.toUpperCase()}</td>
                {/* <td>{user.last_login ? `${format(parseISO(user.last_login), "dd MMMM HH:mm", { locale: i18n.language === "de" ? de : null })} (${formatDistanceToNow(parseISO(user.last_login), { locale: i18n.language === "de" ? de : null, addSuffix: true })})` : "-"}</td> */}
                <td>{user.last_login ? `${formatDistanceToNow(parseISO(user.last_login), { locale: i18n.language === "de" ? de : null, addSuffix: true })}` : "-"}</td>

                {/* <td><span className={user.last_login ? (isToday(parseISO(user.last_login)) || isYesterday(parseISO(user.last_login)) ? "badge bg-success" : "badge bg-warning") : "-"}>{user.last_login ? (isToday(parseISO(user.last_login)) || isYesterday(parseISO(user.last_login)) ? t("Yes") : t("No")) : "-"}</span></td> */}
            </tr>
        ));
    };

    return (
        <React.Fragment>
            <Helmet title="User" />
            <Container fluid className="p-0">
                <Row className="mb-2 mb-xl-3">
                    <Col xs="auto" className="d-none d-sm-block">
                        <h3>{t("User")}</h3>
                    </Col>
                    <Col xs="auto" className="ms-auto text-end mt-n1">

                        {/* <Dropdown align={{ lg: 'end' }} className="d-inline">
                            <Dropdown.Toggle variant="light" className="bg-white shadow-sm me-1">
                                <Filter className="feather align-middle" /> {t(filterButtonTitle)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => filterBy("admin", "Filtered: Admin")}>{t("cap-on Admin")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => filterBy("cap-on agent", "Filtered: cap-on Agent")}>{t("cap-on agent")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => filterBy("regular", "Filtered: Regular")}>{t("Regular")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => filterBy("device", "Filtered: Devices")}>{t("Devices")}</Dropdown.Item>
                                <Dropdown.Divider />

                                <Dropdown.Item onClick={() => filterBy("all", "Filter")}>{t("All")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        <AddUserModal />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ErrorHandler error={error} />
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t("Search")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t("Name / Email")}
                                                value={searchTerm}
                                                onChange={handleSearch}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col >
                                        <Form.Group>
                                            <Form.Label>{t("Tenant")}</Form.Label>
                                            <Select
                                                options={tenants}
                                                value={selectedTenant}
                                                onChange={filterByTenant}
                                                isClearable
                                                placeholder={t("Select")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col >
                                        <Form.Group>
                                            <Form.Label>{t("Role")}</Form.Label>
                                            <Select
                                                options={roles}
                                                value={selectedRole}
                                                onChange={filterByRole}
                                                isClearable
                                                placeholder={t("Select")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col >
                                        <Form.Group>
                                            <Form.Label>{t("Hide Device Users")}</Form.Label>
                                            <Form.Check
                                                type="checkbox"
                                                // label={t("Hide Devices")}
                                                checked={hideDevices}
                                                onChange={handleHideDevices}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-muted text-center py-3">{filteredData?.length} {t("users")} </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                <th scope="col">{t("Status")}</th>
                                                <th scope="col">{t("")}</th>
                                                <th scope="col">{t("Name")}</th>
                                                {/* <th scope="col">{t("Last name")}</th> */}
                                                <th scope="col">{t("Position")}</th>
                                                {/* <th scope="col">{t("Email")}</th> */}
                                                <th scope="col">{t("Role")}</th>
                                                {/* <th scope="col">{t("Phone Number")}</th> */}
                                                {/* <th scope="col">{t("Language")}</th> */}
                                                <th scope="col">{t("Last login")}</th>
                                                {/* <th scope="col">{t("Recently active")}</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>{renderTable()}</tbody>
                                    </Table>
                                </Row>
                            </Card.Body>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment >
    );
};

export default Users;
