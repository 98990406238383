import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLogsBaseURL } from "../../config";

function BaseURLsOptions({ selectedOption }) {

  const baseUrls = [
    "ettlinger.cap-on.de",
    "ettlinger.cap-on.io",
    "possehl.cap-on.de",
    "possehl.cap-on.io",
    "tha.cap-on.de",
    "maag.cap-on.de",
    "hexpol.cap-on.de",
    "maag.cap-on.io",
    "klexu.cap-on.de",
    "klexu.cap-on.io",
    "weima.cap-on.de",
    "weima.cap-on.io",
    "siemens.cap-on.de",
    "siemens.cap-on.io",
    "kontron.cap-on.de",
    "chg-meridian.cap-on.de",
    "kontron.cap-on.io",
    "sueddeutsche-leasing.cap-on.de",
    "sueddeutsche-leasing.cap-on.io",
    "thyssenkruppAB.cap-on.de",
    "thyssenkruppAB.cap-on.io",
    "thyssenkruppAE.cap-on.de",
    "thyssenkruppAE.cap-on.io",
    "linde-wiemann.cap-on.de",
    "linde-wiemann.cap-on.io",
    "wegoma.cap-on.de",
    "wegoma.cap-on.io",
  ]

  return baseUrls?.sort((a, b) => a?.localeCompare(b))?.map((url) => {
    return (
      <option key={url} value={url} selected={selectedOption === url}>{url}</option>
    );
  });

};

export default BaseURLsOptions;
