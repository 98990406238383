import { useContext, useEffect, useState } from "react";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import OPCUA_Logo_Gross from "../../assets/img/logos/opc_logo.png";
import NodeBrowser from "./GatewayOPCUANodeTreeRenderer";
import SingleIoTDataPointModal from "../Assets/IoT_Data_Setup/SingleIoTDataPointModal";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import qs from "qs";
import NotyfContext from "../../contexts/NotyfContext";
import { Clipboard } from "react-feather";

const GatewayOPCUARemoteClientModal = ({ gatewayData, opc_endpoint, opc_user, opc_pw, handleClose }) => {
  const { t, i18n } = useTranslation();
  const notyf = useContext(NotyfContext);

  function copyToClipboard(command, notificationMessage) {

    navigator.clipboard.writeText(command)

    notyf.open({
      type: "success",
      message: notificationMessage,
      duration: "6000",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  };

  return (
    <Modal size="xl" show onHide={handleClose} style={{ zIndex: 1050 }}>
      <Modal.Body>

        <div className="text-center">
          <Row>
            <Col>
              <div className="mt-6">
                <h4>Start port forwarding from your local machine</h4>
                <code>{`ssh -fN -L 4840:${opc_endpoint.replace("opc.tcp://", "")} admin@${gatewayData?.internal_ip}`}
                </code>
                <Button
                  variant="outline-secondary"
                  className="shadow-sm ms-2"
                  size="sm"
                  onClick={() => copyToClipboard(`ssh -fN -L 4840:${opc_endpoint.replace("opc.tcp://", "")} admin@${gatewayData?.internal_ip}`, t("Termin-Command copied. Now paste into Terminal and use your local opc-ua client"))}
                ><Clipboard className="feather" />
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mt-6">
                <h4>Open OPC UA client on your local machine</h4>
                <code>{`opcua-client`}
                </code>
                <Button
                  variant="outline-secondary"
                  className="shadow-sm ms-2"
                  size="sm"
                  onClick={() => copyToClipboard(`opcua-client`, t("Copied!"))}
                > <Clipboard className="feather" />
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mt-6 mb-6">
                <h4>Adjust path in OPC UA client UI</h4>
                {
                  opc_user && opc_pw
                    ? <code>{`opc.tcp://${opc_user}:${opc_pw}@localhost:4840`}</code>
                    : <code>{`opc.tcp://localhost:4840`}</code>
                }

                <Button
                  variant="outline-secondary"
                  className="shadow-sm ms-2"
                  size="sm"
                  onClick={() => copyToClipboard(opc_user && opc_pw ? `opc.tcp://${opc_user}:${opc_pw}@localhost:4840` : `opc.tcp://localhost:4840`, t("Copied IP for your local opc-ua client"))}
                > <Clipboard className="feather" />
                </Button>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div className="mt-6 mb-6">
                <h4>Stop the forwarding process</h4>
                <code>kill $(lsof -t -i:4840)</code>
                <Button
                  variant="outline-secondary"
                  className="shadow-sm ms-2"
                  size="sm"
                  onClick={() => copyToClipboard("kill $(lsof -t -i:4840)", t("Copied! Now paste into Terminal"))}
                > <Clipboard className="feather" />
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col className="text-center">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal >
  );
};

export default GatewayOPCUARemoteClientModal;