import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Table,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format, parseISO } from "date-fns";
import usePalette from "../../../hooks/usePalette";
import GatewayNATOptionsModal from "../../Gateways/GatewayNATOptionsModal";
import { Shield } from "react-feather";

const CloudConnectivitySection = ({ assetData, clodConnectivityContentLoaded, loadData }) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [gatewayData, setGatewayData] = useState(false);
  const [assetCloudData, setAssetCloudData] = useState(false);
  const [refresh, set_refresh] = useState(true);
  const palette = usePalette();

  useEffect(() => {

    if (clodConnectivityContentLoaded) {
      axios
        .get("/webservice/assets/" + assetData?.id + "/cloud")
        .then(function (response) {
          // handle success
          console.log(response.status, "xx/cloud");
          setAssetCloudData(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/webservice/iot_gateways/" + assetData?.iot_gateway_id)
        .then(function (response) {
          // handle success
          console.log(response.status, "xx/gateway");
          setGatewayData(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [refresh, clodConnectivityContentLoaded, assetData?.id]);

  const formatDataSize = (sizeInBytes) => {
    const i18nOptions = i18n.language === "de" ? de : "en";
    const sizeInKB = sizeInBytes / 1000;
    const sizeInMB = sizeInKB / 1000;
    const sizeInGB = sizeInMB / 1000;

    if (sizeInGB >= 1) {
      return `${sizeInGB.toLocaleString(i18nOptions, { maximumFractionDigits: 2 })} GB`;
    } else if (sizeInMB >= 1) {
      return `${sizeInMB.toLocaleString(i18nOptions, { maximumFractionDigits: 2 })} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toLocaleString(i18nOptions, { maximumFractionDigits: 2 })} KB`;
    } else {
      return `${sizeInBytes.toLocaleString(i18nOptions, { maximumFractionDigits: 2 })} Bytes`;
    }
  };

  const avgDailyDataSizeBytes = assetCloudData?.avg_daily_data_points * 220;
  const avgMonthlyDataSizeBytes = avgDailyDataSizeBytes * 30;


  const renderConnectionState = (status) => {

    switch (status) {
      case "connected":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t(status)}</Badge>
        )
      case "connection_lost":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t(status)}</Badge>
        )
      default:
        break;
    }
  }

  const [showNATOptionsModal, setShowNATOptionsModal] = useState();

  return (
    <div style={{ background: palette["gray-200"], padding: "20px" }}>

      {showNATOptionsModal && <GatewayNATOptionsModal handleClose={() => setShowNATOptionsModal(false)} gateway_data={gatewayData} />}

      <Row>
        <Col md="6" className="flex-fill">
          {/* Gateway Tile */}
          <Card className="flex-fill">
            <Card.Header>
              <Col xs="auto" className="d-none d-sm-block">
                <Card.Title>
                  {t("IoT-Gateway")}
                </Card.Title>
              </Col>
              <Col xs="auto" className="ms-auto text-end mt-n3">
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="shadow-sm me-1"
                  onClick={() => setShowNATOptionsModal(true)}>
                  <Shield className="feather" /> {t("VPN Remote Service Access")}
                </Button>
                <Button
                  onClick={() => navigate("/gateways/" + assetCloudData?.iot_gateway.id)}
                  variant="outline-primary"
                  size="sm"
                  className="shadow-sm">
                  {t("More info")}
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>{t("Connection")}</th>
                    <td>{assetCloudData?.iot_gateway == null ? "" : renderConnectionState(assetCloudData?.iot_gateway.connection_status)}</td>
                  </tr>
                  <tr>
                    <th>{t("Serial #")}</th>
                    <td>{assetCloudData?.iot_gateway == null ? "" : assetCloudData?.iot_gateway.serial}</td>
                  </tr>
                  <tr>
                    <th>{t("Last handshake")}</th>
                    <td>{assetCloudData?.iot_gateway == null ? "-" : assetCloudData?.iot_gateway.last_handshake == null ? "-" : format(parseISO(assetCloudData?.iot_gateway.last_handshake.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                  </tr>
                  <tr>
                    <th>{t("Last production unit")}</th>
                    <td>{assetCloudData?.iot_gateway == null ? "-" : assetCloudData?.iot_gateway.last_usage_log == null ? "-" : format(parseISO(assetCloudData?.iot_gateway.last_usage_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                  </tr>
                  <tr>
                    <th>{t("Last activity")}</th>
                    <td>{assetCloudData?.iot_gateway == null ? "-" : assetCloudData?.iot_gateway.last_asset_log == null ? "-" : format(parseISO(assetCloudData?.iot_gateway.last_asset_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                  </tr>
                  <tr>
                    <th>{t("Internet speed (Upload)")}</th>
                    <td>{assetCloudData?.iot_gateway == null ? "" : assetCloudData?.iot_gateway.last_handshake == null ? "" : assetCloudData?.iot_gateway.last_handshake.internet_speed_up == null ? "-" : assetCloudData?.iot_gateway.last_handshake.internet_speed_up.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'decimal',
                      maximumFractionDigits: 1,
                    }) + " Mbit/s"}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Data transfer")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  <tr>
                    <th>{t("Average daily data points")}</th>
                    <td>
                      <strong>{assetCloudData?.avg_daily_data_points?.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0 })}</strong> {t("data points")}<br />
                    </td>
                  </tr>
                  <>
                    <tr>
                      <th>{t("Average 1-day data")}</th>
                      <td>
                        <strong>{formatDataSize(avgDailyDataSizeBytes)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>{t("Average 30-day data")}</th>
                      <td>
                        <strong>{formatDataSize(avgMonthlyDataSizeBytes)}</strong>
                      </td>
                    </tr>
                  </>
                </tbody>
              </Table>
            </Card.Body>
          </Card></Col>
      </Row>
    </div>

  )
}

export default CloudConnectivitySection;
