import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO, formatDistanceToNow } from 'date-fns'
import { de } from "date-fns/locale"
// import useUserContext from "./UserData";
import ErrorHandler from "../../Helper/ErrorHandler";
import AddTenantModal from "./TenantModal_Add";
import EditTenantModal from "./TenantModal_Edit";
import { getIAMBaseURL } from "../../../config";

const Tenants = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation();
    const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

    useEffect(() => {
        axios
            .get(getIAMBaseURL() + "/tenants/")
            .then(function (response) {
                // handle success
                console.log(response.status, "/tenants");

                setData(response.data.sort((a, b) => parseISO(a.last_login) < parseISO(b.last_login)));
            })
            .catch(function (error) {
                // handle error
                setError({ showMessage: true, errorData: error })
                console.log(error);
            });
    }, []);

    const loadAvatar = (url) => {
        return (
            <img
                src={url == null ? null : url}
                className="center"
                alt="avatar"
                width={28} height={28}
            />);
    };

    const renderTable = () => {
        return data?.map((tenant) => {
            return (
                <tr key={tenant.id} >
                    <td>{tenant?.id}</td>
                    <td >{tenant == null ? null : loadAvatar(tenant?.avatar_url)}</td>
                    <td>{tenant?.name?.toUpperCase() || "-"}</td>
                    <td>{tenant?.url}</td>
                    <td>{tenant.created_at === null ? "-" : format(parseISO(tenant.created_at), "dd MMMM HH:mm", { locale: i18n.language === "de" ? de : null }) + " (" + formatDistanceToNow(parseISO(tenant.created_at), { locale: i18n.language === "de" ? de : null, addSuffix: true }) + ")"}</td>

                    <td className="table-action">
                        <EditTenantModal tenant_data={tenant} id={tenant.id} />
                    </td>
                </tr >
            );
        });
    };

    return (
        <React.Fragment>
            <Helmet title="User" />
            <Container fluid className="p-0">
                <Row className="mb-2 mb-xl-3">
                    <Col xs="auto" className="d-none d-sm-block">
                        <h3>{t("Tenants")}</h3>
                    </Col>
                    <Col xs="auto" className="ms-auto text-end mt-n1">
                        <AddTenantModal />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ErrorHandler error={error} />
                        <Card>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th scope="col">{t("ID")}</th>
                                        <th scope="col">{t("Logo")}</th>
                                        <th scope="col">{t("Name")}</th>
                                        {/* <th scope="col">{t("Environment")}</th> */}
                                        <th scope="col">{t("URL")}</th>
                                        <th scope="col">{t("Created")}</th>
                                    </tr>
                                </thead>
                                <tbody>{renderTable()}</tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Tenants;
