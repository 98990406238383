import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Dropdown, Table, Badge, OverlayTrigger, Popover } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { ArrowUpRight, Clipboard, Cpu, HelpCircle, Shield } from "react-feather";

const GatewayNATOptionsModal = ({ handleClose, gateway_data }) => {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const { t, i18n } = useTranslation();
  const [natOptions, setNatOptions] = useState([])
  // const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {

    // console.log("--->", gateway_data)

    updateNATOptions();

    // Set an interval to update NAT options every 3 seconds
    const intervalId = setInterval(() => {
      updateNATOptions();
    }, 5000);

    // Clear the interval when the modal is unmounted or closed
    return () => clearInterval(intervalId);
  }, []);

  function updateNATOptions() {
    axios
      .get(`/iot_gateways/${gateway_data?.id}/nat_options`)
      .then(function (response) {
        // handle success
        console.log(response.status, "/nat_options")

        setNatOptions(response.data.sort((a, b) => a.first_name > b.first_name))
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const handleActivation = (e, nat_option_entry) => {

    e.preventDefault();

    let slug = nat_option_entry?.active ? "deactivate" : "activate"

    axios
      .post(`/iot_gateways/${gateway_data?.id}/nat_options/${nat_option_entry?.id}/${slug}`)
      .then(function (response) {
        // handle success
        console.log(response.status, `/${slug} NAT`);

        notyf.open({
          type: "success",
          message: t("This will take around 20s to take effect"),
          duration: "8000",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        updateNATOptions()

        console.log("refreeeesh here!!")

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

  };

  function copyToClipboard(command, notificationMessage) {

    navigator.clipboard.writeText(command)

    notyf.open({
      type: "success",
      message: notificationMessage,
      duration: "6000",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  };

  function getDescriptionSwitch(port) {
    switch (port) {
      case 102:
        return "Siemens TIA Portal"
      case 5001:
        return "Siemens TP Comfort Panel"
      case 5002:
        return "Siemens TP Comfort Panel"
      case 1880:
        return "Node-RED"
      case 4840:
        return "OPC UA"
      case 502:
        return "Modbus"
      default:
        return "-"
    }
  }

  return (
    <Modal size="xl" show onHide={handleClose}>
      <Modal.Header closeButton> <Shield className="feather" /></Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>

        <Row className="mt-2 mb-5">
          <Col>
            <div className="h4 mb-4">{t("VPN Remote Access")}</div>
            {t("Data forwarding lets the cap-on IoT-Gateway send data from a specific port to a device or application on your local machine. It connects external systems to services like web servers or applications running locally by mapping external ports to internal ones. This makes it easy to access and interact with local devices or software remotely. For you, this simplifies making local (VPN) services accessible from outside the network.")}
          </Col>
        </Row>

        <Row>
          <Col>
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Status")}</th>
                  <th scope="col">{t("Description")}</th>
                  <th scope="col">{t("Desired Port")}</th>
                  {/* <th scope="col">{t("Local Port")}</th> */}
                  <th scope="col">{t("Last Activation")}</th>
                  {/* <th scope="col">{t("Last User Activation")}</th> */}
                </tr>
              </thead>
              <tbody>{natOptions?.length === 0
                ? <div className="mt-4 px-4">{t("For security reasons, these options need to be defined on the IoT-Gateway itself. Please contact the administrator.")}</div>
                : natOptions?.map((entry) => {
                  return (
                    <tr key={entry.id}>
                      <td>
                        <Badge bg="" className={`badge-soft-${entry?.active ? "success" : "secondary"} me-2`}>
                          {entry?.active ? t("Activated") : t("Deactivated")}
                        </Badge>
                      </td>
                      <td>
                        {/* <strong>{t(entry?.description) || "-"}</strong> */}
                        <strong>{getDescriptionSwitch(entry?.device_port)}</strong>
                      </td>
                      {/* <td>{entry?.device_ip || "-"}:{entry?.device_port || "-"} → <strong>{gateway_data?.internal_ip}:{entry?.local_port || "-"}</strong></td> */}
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          key={entry?.id}
                          overlay={
                            <Popover>
                              <Popover.Header as="h3">Port Forwarding</Popover.Header>
                              <Popover.Body>
                                <div className="mb-4">
                                  {entry?.device_ip || "-"}:{entry?.device_port || "-"} → <strong>{gateway_data?.internal_ip}:{entry?.local_port || "-"}</strong>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <span>
                            {entry?.device_port || "-"} <HelpCircle className="feather ms-2 mb-1" />
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td>
                        {
                          entry?.time_last_activated
                            ? parseISO(entry.time_last_activated) < (new Date() - 24 * 60 * 60 * 1000)
                              ? format(parseISO(entry.time_last_activated), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })
                              : formatDistanceToNow(parseISO(entry?.time_last_activated), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                            : "-"
                        }
                      </td>
                      {/* <td>{entry?.user_last_activated || "-"}</td> */}
                      <td>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="shadow-sm me-1"
                          onClick={() => copyToClipboard(`${gateway_data?.internal_ip}:${entry?.local_port}`, t("Command copied."))}>
                          {/* {t("Copy")} */}
                          <Clipboard className="feather" />
                        </Button>
                        <Button
                          variant={entry.active ? "secondary" : "success"}
                          size="sm"
                          className="shadow-sm me-1"
                          onClick={(e) => window.confirm(`Are you sure you wish to toggle "${getDescriptionSwitch(entry?.device_port)}"?`) && handleActivation(e, entry)}>
                          {entry.active ? t("Deactivate") : t("Activate")}
                        </Button>
                      </td>
                    </tr>
                  );
                })}</tbody>
            </Table>

          </Col>
        </Row>

        <br />
        <hr />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal >
  );
};

export default GatewayNATOptionsModal;