import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Modal, Table, Dropdown, Spinner, Badge, OverlayTrigger, Popover, ProgressBar, Tab, Nav } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import { Activity, MoreHorizontal, Info, Wifi, HardDrive, Compass, Clock, DownloadCloud, Clipboard, RefreshCw, Gitlab, Code, File, Search, Check, Trash, Settings, Cpu, CheckSquare, Shield } from "react-feather";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { format, formatDuration, intervalToDuration, differenceInSeconds, formatDistanceToNow, parseISO } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from "date-fns/locale"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import VodafoneLogo from "../../assets/img/ISP/vodafone_logo.jpg";
import VSENETLogo from "../../assets/img/ISP/VSE-NET.jpg";
import OPCUA_Logo from "../../assets/img/logos/opc_ua.png";
import OPCUA_Logo_Gross from "../../assets/img/logos/opc_logo.png";
import mqtt_Logo from "../../assets/img/logos/mqtt.png";
import beckhoff_Logo from "../../assets/img/logos/beckhoff.png";
import modbus from "../../assets/img/logos/modbus.png";
import ErrorHandler from "../Helper/ErrorHandler";
import { isMobile } from "react-device-detect";
import UserDataManager from "../Helper/UserDataManager";
import NotyfContext from "../../contexts/NotyfContext";
import LoadingSpinner from "../Helper/LoadingSpinner";
import simatic from "../../assets/img/simatic.png";
import rpi from "../../assets/img/rpi.png";
import { getGatewayVPNBaseURL } from "../../config";
import GatewayVPNContentModal from "./GatewayVPNContentModal";
import ActivateGatewayModal from "./GatewayModal_Activation";
import SingleIoTGatewayModal from "./SingleIoTGatewayModal";
import GatewayNATOptionsModal from "./GatewayNATOptionsModal";
import GatewayJobOptionsModal from "./GatewayJobOptionsModal";

const GatewayDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [gatewayData, setGatewayData] = useState([]);
  // const [lastMissingHandshakeData, setLastMissingHandshakeData] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(true);
  // const [reliabilityScore, setReliabilityScore] = useState(true);
  const notyf = useContext(NotyfContext);

  const [isLoadingRemoteCall, setIsLoadingRemoteCall] = useState(false)
  const [showContentModal, setShowContentModal] = useState()
  const [gatewayVPNResponse, setGatewayVPNResponse] = useState()

  useEffect(() => {
    loadGatewayDetails()
  }, []);

  function loadGatewayDetails() {
    axios
      .get("/webservice/iot_gateways/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "/iot_gateway");

        setGatewayData(response.data);
        // setLastMissingHandshakeData(response.data?.last_missing_handshake);
        // setReliabilityScore(response.data?.score);

        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
        setIsLoading(false);
      });
  }

  const ShowLoadingSpinner = () => {

    return (
      <div className="text-center mb-3">
        <br />
        <Spinner animation="border" variant="secondary" />
        <br />{t("Accessing remote data")}
      </div>
    )
  }

  // const ShowLoadingSpinnerModal = ({ show }) => {

  //   return (<Modal size="sm" show={show} >
  //     <Modal.Body>
  //       <div className="text-center mb-3">
  //         <img
  //           src={OPCUA_Logo_Gross}
  //           alt="avatar"
  //           className="my-4"
  //           width="auto"
  //           height={100}
  //         />
  //         <br />
  //         <Spinner animation="border" variant="secondary" className="mb-4" />
  //         <div className="h3">{t("Browsing OPC UA Server")}</div>
  //         <div>{t("This will take a few seconds. Lean back!")}</div>
  //       </div>
  //     </Modal.Body >
  //   </Modal >
  //   )
  // }

  function handleTrigger(event, method, action) {
    event?.preventDefault();

    setIsLoadingRemoteCall(true)

    // axios
    //   .post(getGatewayVPNBaseURL() + "/gateways/trigger", {
    //     "method": method,
    //     "path": action,
    //     "gateway_ip": gatewayData?.internal_ip,
    //     "port": "1880",
    //     "username": "cap-on_admin",
    //     "password": gatewayData?.node_red_password || "aehek*j+xoj^v2b9"
    //   }
    //   )
    axios
      .post(`/webservice/iot_gateways/${gatewayData?.id}/nodered`, {
        "method": method,
        "path": action
      }
      )
      .then(function (response) {
        // handle success
        console.log(response.status, `/triggered "${action}"`);
        setGatewayVPNResponse(response.data)

        if (response.data?.modalView) {
          setShowContentModal(true)
        }

        notyf.open({
          type: response.data?.success ? "success" : "warning",
          message: `${response.data?.message ? response.data?.message : "Success"}`,
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        setIsLoadingRemoteCall(false)

      })
      .catch(function (error) {
        // handle error

        notyf.open({
          type: "warning",
          message: t("An error occured"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
        console.log(error);
        setIsLoadingRemoteCall(false)
      });
  }

  function handleDelete(e) {
    e.preventDefault();

    axios
      .delete("/iot_gateways/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted gateway");
        navigate("/gateways");
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function handleDeleteActivity(e, activity_id) {
    e.preventDefault();

    axios
      .delete(`/iot_gateways/${id}/con_activities/${activity_id}`)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted gateway");
        loadGatewayDetails()
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }




  const ShowISP = ({ extended }) => {

    if (gatewayData?.length === 0 | gatewayData?.last_handshake === undefined) {
      return (
        <td>{gatewayData?.last_handshake == null ? "-" : gatewayData?.last_handshake.isp == null ? "-" : gatewayData?.last_handshake.isp}</td>
      )
    } else {

      switch (gatewayData?.last_handshake?.isp) {
        case "Vodafone Germany":
          return (
            <div>
              <img
                src={VodafoneLogo}
                alt="avatar"
                width="auto"
                height={20}
              />
              {extended && <a>{" "}{gatewayData?.last_handshake == null ? "-" : gatewayData?.last_handshake.isp == null ? "-" : gatewayData?.last_handshake.isp}</a>}
            </div>
          )
        case "Vodafone GmbH":
          return (
            <div>
              <img
                src={VodafoneLogo}
                alt="avatar"
                width="auto"
                height={20}
              />
              {extended && <a>{" "}{gatewayData?.last_handshake == null ? "-" : gatewayData?.last_handshake.isp == null ? "-" : gatewayData?.last_handshake.isp}</a>}
            </div>
          )
        case "VSE NET GmbH":
          return (
            <div>
              <img
                src={VSENETLogo}
                alt="avatar"
                width="auto"
                height={20}
              />
              {extended && <a>{" "}{gatewayData?.last_handshake == null ? "-" : gatewayData?.last_handshake.isp == null ? "-" : gatewayData?.last_handshake.isp}</a>}
            </div>
          )
        default:
          return (
            <div>{gatewayData?.last_handshake?.isp || "-"}</div>
          )
      }
    }
  }

  // const loadHeader = () => {

  //   return (
  //     <Row>
  //       <Col md="6" xl className="d-flex">
  //         <Card className="flex-fill">
  //           <Card.Body className=" py-4">
  //             <div className="flex-grow-1">
  //               <h3 className="mb-2">
  //                 {gatewayData == null ? "n/a" : renderConnectionState(gatewayData?.connection_status)}
  //               </h3>
  //               <p className="mb-0">{t("Connection")}</p>
  //             </div>
  //           </Card.Body>
  //         </Card>
  //       </Col>
  //       <Col md="6" xl className="d-flex">
  //         <Card className="flex-fill">
  //           <Card.Body className=" py-4">
  //             <div className="flex-grow-1">
  //               <h3 className="mb-2">{gatewayData?.last_handshake === null || gatewayData?.last_handshake?.time === null ? "-" : format(parseISO(gatewayData?.last_handshake?.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</h3>
  //               <p className="mb-0">{t("Last handshake")} ({gatewayData?.last_handshake === null || gatewayData?.last_handshake?.time === null ? "-" : formatDistanceToNow(parseISO(gatewayData?.last_handshake.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })})</p>
  //             </div>
  //           </Card.Body>
  //         </Card>
  //       </Col>
  //       <Col md="6" xl className="d-flex">
  //         <Card className="flex-fill">
  //           <Card.Body className=" py-4">
  //             <div className="flex-grow-1">
  //               <h3 className="mb-2">{gatewayData?.latest_speedtest == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_down == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_down?.toLocaleString(i18n.language === "de" ? de : "en", {
  //                 style: "decimal",
  //                 maximumFractionDigits: 2
  //               }) + " Mbit/s"}</h3>
  //               <p className="mb-0">{t("Internet speed (Download)")} ({gatewayData?.latest_speedtest === null || gatewayData?.latest_speedtest?.time === null ? "-" : formatDistanceToNow(parseISO(gatewayData?.latest_speedtest?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })})</p>
  //             </div>
  //           </Card.Body>
  //         </Card>
  //       </Col>
  //       <Col md="6" xl className="d-flex">
  //         <Card className="flex-fill">
  //           <Card.Body className=" py-4">
  //             <div className="flex-grow-1">
  //               <h3 className="mb-2">{gatewayData?.latest_speedtest == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_up == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_up?.toLocaleString(i18n.language === "de" ? de : "en", {
  //                 style: "decimal",
  //                 maximumFractionDigits: 2
  //               }) + " Mbit/s"}</h3>
  //               <p className="mb-0">{t("Internet speed (Upload)")}  ({gatewayData?.latest_speedtest === null || gatewayData?.latest_speedtest?.time === null ? "-" : formatDistanceToNow(parseISO(gatewayData?.latest_speedtest?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })})</p>
  //             </div>
  //           </Card.Body>
  //         </Card>
  //       </Col>
  //     </Row>
  //   )
  // }

  // const renderConnectionState = (status) => {

  //   switch (status) {
  //     case "connected":
  //       return (
  //         <Badge bg="" className="badge-soft-success me-2">{t("Cloud")}</Badge>
  //       )
  //     case "connection_lost":
  //       return (
  //         <Badge bg="" className="badge-soft-warning me-2">{t("Cloud")}</Badge>
  //       )
  //     default:
  //       return (
  //         <Badge bg="" className="badge-soft-warning me-2">{t("Not connected")}</Badge>
  //       )
  //   }
  // }

  function getRecentAssetLogStatus(log) {
    switch (log) {
      case true:
        return <Badge bg="" className="badge-soft-success me-2">{t("Recent activity")}</Badge>
      case false:
        return <Badge bg="" className="badge-soft-warning me-2">{t("No recent activity")}</Badge>
      default:
        return <Badge bg="" className="badge-soft-secondary me-2">-</Badge>
    }
  }

  function getRecentProductionStatus(recent_production) {
    switch (recent_production) {
      case true:
        return <Badge bg="" className="badge-soft-success me-2">{t("Unit recently produced")}</Badge>
      case false:
        return <Badge bg="" className="badge-soft-warning me-2">{t("Last production unit: >24h")}</Badge>
      default:
        return <Badge bg="" className="badge-soft-secondary me-2">-</Badge>
    }
  }

  const renderVPNConnectionState = (time) => {

    const currentTime = new Date();
    const inputTime = new Date(time);

    const timeDifference = (currentTime.getTime() - inputTime.getTime()) / (1000 * 60); // Convert milliseconds to minutes

    const isWithinLast10Minutes = timeDifference <= 10;

    if (isWithinLast10Minutes) {
      return <Badge bg="" className="badge-soft-success me-2">{t("VPN")}</Badge>
    } else {
      return <Badge bg="" className="badge-soft-warning me-2">{t("VPN")}</Badge>
    }
  }

  function getConnectionStatus(item) {
    switch (item) {
      case "connected":
        return <Badge bg="" className="badge-soft-success me-2">{t("PLC connected")}</Badge>
      case "no_connection":
        return <Badge bg="" className="badge-soft-secondary me-2">{t("no_connection")}</Badge>
      case "disconnected":
        return <Badge bg="" className="badge-soft-danger me-2">{t("PLC")} {t("disconnected")}</Badge>
      default:
        return <Badge bg="" className="badge-soft-warning me-2">{t("PLC connected")}</Badge>
    }
  }

  const ConnectionOverview = () => {

    return (
      <Row>
        <Col md="12" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row className="align-items-center">
                <Col md="2">
                  <div onClick={() => navigate("/assets/" + gatewayData?.assets[0]?.id)} className="py-3 px-3 flex-grow-1" style={{ borderRadius: '15px', cursor: "pointer", backgroundColor: "rgba(0, 0, 0, 0.05)" }}>

                    {gatewayData?.asset_avatar_url !== "https://core-system.s3.eu-central-1.amazonaws.com/client_placeholder.png" && <span className="d-inline-block">
                      <img
                        src={gatewayData?.asset_avatar_url || "-"}
                        alt="avatar"
                        width="auto"
                        className="mb-3"
                        height={80}
                      />
                    </span>}

                    <h3>{gatewayData?.assets?.length > 0 && (gatewayData?.assets?.length > 0 && (gatewayData?.assets[0]?.title || "-"))}</h3>
                    <p className="mb-0">Asset</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="text-center py-3 px-3 flex-grow-1" >

                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("PLC Connection")}</Popover.Header>
                          <Popover.Body>
                            <span className="d-inline-block">
                              {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "opc_ua" && <img
                                src={OPCUA_Logo}
                                alt="avatar"
                                width="auto"
                                height={15}
                              />}
                              {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "mqtt" && <img
                                src={mqtt_Logo}
                                alt="avatar"
                                width="auto"
                                height={15}
                              />}
                              {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "ads" && <img
                                src={beckhoff_Logo}
                                alt="avatar"
                                width="auto"
                                height={15}
                              />}
                            </span>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {getConnectionStatus(gatewayData?.connection_status)}
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Last activity")}</Popover.Header>
                          <Popover.Body>
                            {
                              gatewayData?.last_asset_log?.time
                                ? format(parseISO(gatewayData?.last_asset_log?.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })
                                : "-"
                            }
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {getRecentAssetLogStatus(gatewayData?.recent_activity)}
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Last production unit")}</Popover.Header>
                          <Popover.Body>
                            {gatewayData == null ? "n/a" : gatewayData?.last_usage_log?.time == null ? "-" : format(parseISO(gatewayData?.last_usage_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })} {gatewayData == null ? "n/a" : gatewayData?.last_usage_log == null ? "-" : t(" (Units: ") + gatewayData?.last_usage_log.amount + ")"}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {getRecentProductionStatus(gatewayData?.recent_production)}
                    </OverlayTrigger>


                    <svg width="200" height="50" viewBox="0 0 200 80" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <marker id="arrowhead-left" markerWidth="10" markerHeight="7"
                          refX="10" refY="3.5" orient="auto">
                          <polygon points="10 0, 0 3.5, 10 7" fill="rgba(0, 0, 0, 0.5)" />
                        </marker>
                        <marker id="arrowhead-right" markerWidth="10" markerHeight="7"
                          refX="0" refY="3.5" orient="auto">
                          <polygon points="0 0, 10 3.5, 0 7" fill="rgba(0, 0, 0, 0.5)" />
                        </marker>
                      </defs>
                      <line x1="10" y1="50" x2="190" y2="50" stroke="rgba(0, 0, 0, 0.5)"
                        strokeWidth="2" markerEnd="url(#arrowhead-right)" />
                    </svg>

                    <span>
                      <br />
                      {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "opc_ua" && <img
                        src={OPCUA_Logo}
                        alt="avatar"
                        width="auto"
                        height={15}
                      />}
                      {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "mqtt" && <img
                        src={mqtt_Logo}
                        alt="avatar"
                        width="auto"
                        height={15}
                      />}
                      {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "ads" && <img
                        src={beckhoff_Logo}
                        alt="avatar"
                        width="auto"
                        height={15}
                      />}
                      {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "modbus" && <img
                        src={modbus}
                        alt="avatar"
                        width="auto"
                        height={15}
                      />}
                      <br /><br />
                    </span>
                  </div>
                </Col>
                <Col md="2">
                  <div className="py-3 px-3 flex-grow-1" style={{ borderRadius: '15px', backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                    <span className="d-inline-block">
                      {gatewayData?.serial?.includes("IOT2050") &&
                        <img
                          src={simatic}
                          alt="avatar"
                          width="auto"
                          className="mb-3"
                          height={80}
                        />}
                      {gatewayData?.serial?.includes("CBRPIX") &&
                        <img
                          src={rpi}
                          alt="avatar"
                          width="auto"
                          className="mb-3"
                          height={80}
                        />}
                    </span>
                    <h3>cap-on IoT-Gateway</h3>
                    <p className="mb-0">{gatewayData?.serial}</p>

                  </div>
                </Col>
                <Col md="3">
                  <div className="text-center py-3 px-3 flex-grow-1" >
                    {/* <h2>Asset</h2> */}
                    {/* <h3 className="mb-2">
                      {gatewayData == null ? "n/a" : renderConnectionState(gatewayData?.connection_status)}
                    </h3> */}
                    <div>
                      {UserDataManager.getInstance().isAdmin() && <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Popover>
                            <Popover.Header as='h3'>{t("Heartbeat Agent")}</Popover.Header>
                            <Popover.Body>
                              <strong>{t("Last heartbeat")}</strong>: {gatewayData?.last_heartbeat
                                ? formatDistanceToNow(parseISO(gatewayData.last_heartbeat), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                                : "-"}
                              <br />

                            </Popover.Body>
                          </Popover>
                        }
                      >
                        {gatewayData == null ? "n/a" : renderAgentState(gatewayData)}
                      </OverlayTrigger>}

                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Popover>
                            <Popover.Header as='h3'>{t("Cloud Connection")}</Popover.Header>
                            <Popover.Body>
                              <strong>{t("Last handshake")}</strong>: {gatewayData?.last_handshake?.time ? formatDistanceToNow(parseISO(gatewayData.last_handshake.time), { locale: i18n.language === "de" ? de : null, addSuffix: true }) : "-"}
                              <br />
                              <strong>{t("Public IP")}</strong>: {gatewayData?.last_handshake?.public_ip || "-"}

                            </Popover.Body>
                          </Popover>
                        }
                      >
                        {gatewayData == null ? "n/a" : renderCloudConnectionState(gatewayData?.connection_status)}
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Popover>
                            <Popover.Header as='h3'>{t("VPN Connection")}</Popover.Header>
                            <Popover.Body>
                              <strong>{t("Last seen")}</strong>:   {gatewayData?.tailscale_last_seen ? formatDistanceToNow(parseISO(gatewayData.tailscale_last_seen), { locale: i18n.language === "de" ? de : null, addSuffix: true }) : "-"}<br />
                              <strong>{t("Internal IP")}</strong>: {gatewayData?.internal_ip || "n/a"}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        {gatewayData == null ? "n/a" : renderVPNConnectionState(gatewayData?.tailscale_last_seen)}
                      </OverlayTrigger>
                    </div>
                    <svg width="200" height="50" viewBox="0 0 200 80" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <marker id="arrowhead-left" markerWidth="10" markerHeight="7"
                          refX="10" refY="3.5" orient="auto">
                          <polygon points="10 0, 0 3.5, 10 7" fill="rgba(0, 0, 0, 0.5)" />
                        </marker>
                        <marker id="arrowhead-right" markerWidth="10" markerHeight="7"
                          refX="0" refY="3.5" orient="auto">
                          <polygon points="0 0, 10 3.5, 0 7" fill="rgba(0, 0, 0, 0.5)" />
                        </marker>
                      </defs>
                      <line x1="10" y1="50" x2="190" y2="50" stroke="rgba(0, 0, 0, 0.5)"
                        strokeWidth="2" markerStart="url(#arrowhead-left)" markerEnd="url(#arrowhead-right)" />
                    </svg>

                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Internet Connection")}</Popover.Header>
                          <Popover.Body>
                            <strong>{t("Latest Speedtest")}</strong>: {gatewayData == null ? "n/a" : gatewayData?.latest_speedtest?.time == null ? "n/a" : format(parseISO(gatewayData?.latest_speedtest?.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}
                            <br />
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div className="mb-2">
                        {" ↓ "}
                        {gatewayData?.latest_speedtest == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_down == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_down?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: "decimal",
                          maximumFractionDigits: 1
                        }) + " Mbit/s"}
                        {" "}
                        {"↑ "}
                        {gatewayData?.latest_speedtest == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_up == null ? "-" : gatewayData?.latest_speedtest?.internet_speed_up?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: "decimal",
                          maximumFractionDigits: 1
                        }) + " Mbit/s"}
                      </div>
                    </OverlayTrigger>

                    <ShowISP />

                  </div>
                </Col>
                <Col md="2">
                  <div className="py-3 px-3 flex-grow-1" style={{ borderRadius: '15px', backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                    <h3>cap-on Cloud</h3>
                    <p className="mb-0">IoT-Hub</p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  const renderCloudConnectionState = (status) => {

    switch (status) {
      case "connected":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Cloud")}</Badge>
        )
      case "connection_lost":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Cloud")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Not connected")}</Badge>
        )
    }
  }


  const renderAgentState = (data) => {
    const logTime = parseISO(data?.last_heartbeat);
    const currentTime = new Date();

    if (differenceInSeconds(currentTime, logTime) <= 40) {
      return (
        <Badge bg="" className="badge-soft-success me-2">{t("Agent")}</Badge>
      );
    } else {
      return (
        <Badge bg="" className="badge-soft-warning me-2">{t("Agent")}</Badge>
      );
    }
  };

  const ViewAssetsDropdownItem = () => {

    return gatewayData?.assets?.map((asset) => {
      return (
        <div key={asset.id}>
          <Dropdown.Item onClick={() => navigate("/assets/" + asset.id)}>{t("View Asset") + ": " + asset.title}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/assets/" + asset.id + "/?focus=digital-twin")}>{t("Digital Twin") + ": " + asset.title}</Dropdown.Item>
        </div>
      );
    });
  }

  const handleButtonClick = (link) => {
    window.open(link, '_blank');
  };

  const GetIndication = () => {
    if (parseISO(gatewayData?.last_missing_handshake?.expected_time) < (new Date(new Date() - 24 * 60 * 60 * 1000 * 28))) {
      return <span className="badge bg-success">{t("Great • 28d+")}</span>
    } else if (parseISO(gatewayData?.last_missing_handshake?.expected_time) < (new Date(new Date() - 24 * 60 * 60 * 1000 * 7))) {
      return <span className="badge bg-success">{t("Good • 7d+")}</span>
    } else if (parseISO(gatewayData?.last_missing_handshake?.expected_time) < (new Date(new Date() - 24 * 60 * 60 * 1000 * 3))) {
      return <span className="badge bg-success">{t("OK • 3d+")}</span>
    } else {
      return <span className="badge bg-danger">{t("Attention • <3d")}</span>
    }
  }

  function handleClipboard(command) {
    navigator.clipboard.writeText(command)

    notyf.open({
      type: "success",
      message: t("shell command copied!"),
      duration: "4500",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  }

  const renderConnectionType = (status) => {

    switch (status) {
      case "lte":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("4G / LTE")}</Badge>
        )
      case "wifi":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Wifi")}</Badge>
        )
      case "ethernet":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Ethernet")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
        )
    }
  }


  const renderConnectivityState = (setting) => {

    switch (setting?.is_active) {
      case false:
        return (
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Popover>
                <Popover.Header as='h3'>{t("Internet Connection")}</Popover.Header>
                <Popover.Body>
                  <strong>{t("Inactive since")}:</strong> {setting?.period_start ? format(parseISO(setting?.period_start), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                  <br />
                  <strong>{t("Inactive until")}:</strong> {setting?.period_end ? format(parseISO(setting?.period_end), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                </Popover.Body>
              </Popover>
            }>
            <Badge bg="" className="badge-soft-secondary me-2">{t("Inactive")}</Badge>
          </OverlayTrigger>
        )

      case true:
        return (
          <OverlayTrigger
            placement='bottom'
            overlay={
              <Popover>
                <Popover.Header as='h3'>{t("Internet Connection")}</Popover.Header>
                <Popover.Body>
                  <strong>{t("Type")}:</strong> {t(setting?.internet_connection || "n/a")}
                  <br /><br />
                  <strong>{t("Activated since")}:</strong> {setting?.period_start ? format(parseISO(setting?.period_start), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                  <br />
                  <strong>{t("Activated until")}:</strong> {setting?.period_end ? format(parseISO(setting?.period_end), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                </Popover.Body>
              </Popover>
            }>
            <Badge bg="" className="badge-soft-success me-2">{t("Activated")}</Badge>
          </OverlayTrigger>
          //   <>
          //   {t("Active")}: {t(`${gatewayData?.connectivity_settings?.internet_connection}`)}
          // </>
        )
      default:
        return (<></>)

    }
  }

  function copyToClipboard(command, notificationMessage) {

    navigator.clipboard.writeText(command)

    notyf.open({
      type: "success",
      message: notificationMessage,
      duration: "6000",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  };

  const [showSingleGateway, setShowSingleGateway] = useState();
  const [showNATOptionsModal, setShowNATOptionsModal] = useState();
  const [showJobOptionsModal, setShowJobOptionsModal] = useState();

  return (
    <React.Fragment>

      {showSingleGateway && <SingleIoTGatewayModal existingGatewayId={showSingleGateway} handleClose={() => {
        setShowSingleGateway(false)
        loadGatewayDetails()
      }} />}

      {showNATOptionsModal && <GatewayNATOptionsModal handleClose={() => setShowNATOptionsModal(false)} gateway_data={gatewayData} />}
      {showJobOptionsModal && <GatewayJobOptionsModal handleClose={() => setShowJobOptionsModal(false)} gateway_data={gatewayData} />}

      <GatewayVPNContentModal data={gatewayVPNResponse} asset_id={gatewayData?.assets?.[0]?.id} show={showContentModal} handleClose={() => { setShowContentModal(false) }} />
      {/* <ShowLoadingSpinnerModal show={isLoadingOPCUACall} /> */}

      <Helmet title="cap-on IoT-Gateway" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
            {/* <div className="vertical-center-all"> */}
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Button>
            <Breadcrumb.Item href="/gateways">{t("Gateways")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Gateway details")}</Breadcrumb.Item>
            {/* </div> */}
          </Breadcrumb>
          <Col>
            <h3>cap-on IoT-Gateway ({gatewayData?.serial})</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end d-flex">

            <Button
              variant={gatewayData?.nat_options?.length === 0 ? "outline-secondary" : "outline-primary"}
              size="sm"
              disabled={gatewayData?.nat_options?.length === 0}
              className="shadow-sm me-1"
              onClick={() => setShowNATOptionsModal(true)}>
              {isMobile ? <Shield className="feather" /> : <><Shield className="feather" /> {t("VPN Remote Service Access")}</>}
            </Button>

            {gatewayData?.assets?.map((asset) => {
              return (
                <div className="d-flex" key={asset.id}>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="shadow-sm me-1"
                    onClick={() => navigate("/assets/" + asset?.id + "/setup")}>
                    {/* <Settings className="feather" />  */}
                    {isMobile ? <Settings className="feather" /> : t("IoT data setup")}
                  </Button>
                  {/* <Button
                    variant="outline-primary"
                    size="sm"
                    className="shadow-sm me-1"
                    onClick={() => navigate("/assets/" + asset.id + "/?focus=digital-twin")}>
                    {isMobile ? <Activity className="feather" /> : t("Digital Twin")}
                  </Button> */}
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="shadow-sm me-1"
                    onClick={() => navigate("/assets/" + asset.id)}>
                    <HardDrive className="feather" /> {isMobile ? "" : t("Connected asset")}
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="shadow-sm me-1"
                    size="sm"
                    onClick={(e) => loadGatewayDetails()}>
                    <RefreshCw className="feather" />
                  </Button>

                </div>
              );
            })}

            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" size="sm" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowSingleGateway(id)}>{t("Edit gateway")}</Dropdown.Item>
                <Dropdown.Divider />
                {gatewayData?.assets?.length > 0 && <ViewAssetsDropdownItem />}
                {UserDataManager.getInstance().isAdmin() && <>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => window.open(("http://" + gatewayData?.internal_ip + ":1880"), '_blank')}>{t("Open Node-RED Editor")}</Dropdown.Item>
                  <Dropdown.Item onClick={() => copyToClipboard("ssh admin@" + gatewayData?.internal_ip, t("Remote ssh Command copied."))}>{t("Copy ssh Command")}</Dropdown.Item>
                </>}

                {/* <Dropdown.Divider />
                {UserDataManager.getInstance().isAdmin() && <Dropdown.Item onClick={() => window.confirm(t("Dies funktioniert nur, wenn Sie sich im cap-on VPN befinden.")) && handleButtonClick("http://" + gatewayData?.internal_ip + ":1880")}>{t("Open NR")}</Dropdown.Item>}
                {UserDataManager.getInstance().isAdmin() && <Dropdown.Item onClick={() => navigate("/application_logs/?username=" + gatewayData?.device_user?.email)}>{t("Gateway Error Log")}</Dropdown.Item>} */}
                <Dropdown.Divider />
                <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to delete this gateway?")) && handleDelete(e)}>{t("Remove Gateway")}</Dropdown.Item>
                <Dropdown.Divider />
                {/* <Dropdown.Item onClick={e => handleClipboard(`ssh -fN -L 102:172.16.1.2:102 admin@${gatewayData?.internal_ip}`)}>{t("Copy TIA Port Forwarding")}</Dropdown.Item>
                <Dropdown.Divider /> */}
                <Dropdown.Item onClick={e => navigate(`/application_logs/?username=${encodeURIComponent(`${gatewayData?.serial}@cap.on.de`)}`)}>{t("Gateway Logs")}</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/gateways/" + gatewayData?.id + "/public")}>{t("View Public Gateway Details")}</Dropdown.Item>
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        {
          isLoading ?
            <LoadingSpinner /> :
            <>
              {/* {loadHeader()} */}
              <ConnectionOverview />

              <Row>
                <Col>
                  <div className={"tab Default"}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="first">{t("About")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">{t("Activation Log")}</Nav.Link>
                        </Nav.Item>
                        {UserDataManager.getInstance().isAdmin() && <Nav.Item>
                          <Nav.Link eventKey="agent">{t("IoT Heartbeat (Agent)")}</Nav.Link>
                        </Nav.Item>}
                        <Nav.Item>
                          <Nav.Link eventKey="third">{t("Handshakes")}</Nav.Link>
                        </Nav.Item>
                        {UserDataManager.getInstance().isAdmin() && <Nav.Item>
                          <Nav.Link eventKey="actions">{t("Remote Actions")}</Nav.Link>
                        </Nav.Item>}
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="h4 mt-2 mb-4 tab-title">{t("About")}</div>
                          <Table size="sm" className="my-2">
                            <tbody>
                              <tr>
                                <th>{t("Serial Number")}</th>
                                <td>{gatewayData?.serial} &nbsp; &nbsp; &nbsp;
                                  <span>
                                    <Button
                                      onClick={() => {
                                        navigator.clipboard.writeText(gatewayData?.serial)

                                        notyf.open({
                                          type: "success",
                                          message: t("Copied!"),
                                          duration: "4500",
                                          ripple: false,
                                          dismissible: true,
                                          position: {
                                            x: "right",
                                            y: "bottom",
                                          },
                                        })
                                      }
                                      }
                                      size="sm"
                                      variant="outline-secondary">
                                      <Clipboard className="feather" />
                                    </Button>
                                  </span>
                                </td>
                              </tr>
                              {UserDataManager.getInstance().isCapOn() && <>
                                <tr>
                                  <th>{t("Gateway ID")}</th>
                                  <td>{gatewayData?.id} &nbsp; &nbsp; &nbsp;
                                    <span>
                                      <Button
                                        onClick={() => {
                                          navigator.clipboard.writeText(gatewayData?.id)

                                          notyf.open({
                                            type: "success",
                                            message: t("Gateway-ID copied!"),
                                            duration: "4500",
                                            ripple: false,
                                            dismissible: true,
                                            position: {
                                              x: "right",
                                              y: "bottom",
                                            },
                                          })
                                        }
                                        }
                                        size="sm"
                                        variant="outline-secondary">
                                        <Clipboard className="feather" />
                                      </Button>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <th>{t("Public Gateway Passcode")}</th>
                                  <td>{gatewayData?.passcode || "-"}</td>
                                </tr>
                              </>}
                              <tr>
                                <th>{t("Asset name (number)")}</th>
                                <td>{`${gatewayData == null ? "-" : gatewayData?.assets?.length === 0 ? "---" : gatewayData?.assets?.length > 0 && (gatewayData?.assets[0]?.title || "-")} (${gatewayData?.assets?.length > 0 && (gatewayData?.assets[0]?.asset_number || "-")})`}</td>
                              </tr>
                              {/* <tr>
                                <th>{t("Last production unit")}</th>
                                <td>{gatewayData == null ? "n/a" : gatewayData?.last_usage_log?.time == null ? "-" : format(parseISO(gatewayData?.last_usage_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })} {gatewayData == null ? "n/a" : gatewayData?.last_usage_log == null ? "-" : t(" (Units: ") + gatewayData?.last_usage_log.amount + ")"}</td>
                              </tr> */}
                              {/* <tr>
                                <th>{t("Last activity")}</th>
                                <td>
                                  {gatewayData?.last_asset_log?.time
                                    ? format(parseISO(gatewayData.last_asset_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })
                                    : "-"
                                  }
                                </td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </Tab.Pane>

                        <Tab.Pane eventKey="second">
                          <Row>
                            <Col>
                              <div className="h4 mt-2 mb-4 tab-title">{t("Activation Log")}</div>
                            </Col>
                            <Col xs="auto" className="ms-auto text-end d-flex">
                              <ActivateGatewayModal gatewayData={gatewayData} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <p className="mb-2 ">{t("State")}</p>
                              <h3>
                                {renderConnectivityState(gatewayData?.connectivity_settings)}
                              </h3>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md="12">
                              <strong>{t("History")}</strong>
                              <ul className="timeline m-3">
                                {
                                  gatewayData?.connectivity_activities?.length > 0
                                    ? gatewayData?.connectivity_activities?.sort((a, b) => a?.target_date > b?.target_date)?.map(item => {
                                      return <li key={item?.id} className="timeline-item">
                                        <strong>
                                          {
                                            item?.paused
                                              ? t("Gateway is inactive. No operating costs will be charged.")
                                              : (`${t("Gateway activated with")} ${t(item?.internet_connection)} (Region: ${item?.region ? t(item?.region) : "No region selected"})`)
                                          }
                                        </strong>
                                        <p className="text">{t("Target date")}: {format(parseISO(item?.target_date), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null, addSuffix: true })}</p>
                                        {UserDataManager.getInstance().isAdmin && <div className="mt-n2 mb-3">
                                          <Button
                                            onClick={e => window.confirm(t("Are you sure you wish to delete this entry?")) && handleDeleteActivity(e, item?.id)}
                                            variant="outline-danger"
                                            className="shadow-sm"
                                            size="sm"
                                          >
                                            <Trash className="feather" />
                                          </Button>
                                        </div>}
                                      </li>
                                    })
                                    : t("No activation recorded, yet.")
                                }
                              </ul>
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="agent">
                          <Row>
                            <Col>
                              <div className="h4 mt-2 mb-4 tab-title">
                                {t("IoT Heartbeat Agent")}
                              </div>
                            </Col>
                            <Col xs="auto" className="ms-auto text-end d-flex">
                              <Button
                                variant={gatewayData?.nat_options?.length === 0 ? "outline-secondary" : "outline-primary"}
                                size="sm"
                                disabled={gatewayData?.nat_options?.length === 0}
                                className="shadow-sm me-1"
                                onClick={() => setShowNATOptionsModal(true)}>
                                {isMobile ? <Shield className="feather" /> : <><Shield className="feather" /> {t("VPN Remote Service Access")}</>}
                              </Button>
                              {UserDataManager.getInstance().isAdmin() && <Button
                                variant="outline-primary"
                                size="sm"
                                className="shadow-sm me-1"
                                onClick={(e) => setShowJobOptionsModal(true)}>
                                {isMobile ? <CheckSquare className="feather" /> : <><CheckSquare className="feather" /> {t("Heartbeat Jobs")}</>}
                              </Button>}
                            </Col>
                          </Row>
                          <Table size="sm" className="my-2">
                            <tbody>
                              <tr>
                                <th>{t("Last Heartbeat")}</th>
                                <td>
                                  {
                                    gatewayData?.last_heartbeat
                                      ? format(parseISO(gatewayData?.last_heartbeat), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })
                                      : "-"
                                  } ({
                                    gatewayData?.last_heartbeat
                                      ? formatDistanceToNow(parseISO(gatewayData.last_heartbeat), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                                      : "-"
                                  })
                                </td>
                              </tr>
                              <tr>
                                <th>{t("Agent Version")}</th>
                                <td>{gatewayData?.agent_version || "-"}</td>
                              </tr>
                              {/* <tr>
                                <th>{t("Hardware Version")}</th>
                                <td>{gatewayData?.hardware_version || "-"}</td>
                              </tr> */}
                              <tr>
                                <th>{t("Firmware Version")}</th>
                                <td>{gatewayData?.firmware_version || "-"}</td>
                              </tr>

                              <tr>
                                <th>{t("ssh Password Enabled")}</th>
                                <td>{gatewayData?.ssh_password_enabled ? t("Yes") : t("No")}</td>
                              </tr>
                              {/* <tr>
                                <th colSpan="2" className="text-center table-section-heading">
                                  {t("Docker")}
                                </th>
                              </tr> */}
                              <tr>
                                <th>{t("Running Container")}</th>
                                <td>
                                  <ul>
                                    {gatewayData.container_running
                                      ?.split(',')
                                      ?.map(item => (
                                        <li key={item.trim()}>{item.trim().toUpperCase()}</li>
                                      ))}
                                  </ul></td>
                              </tr>
                              <tr>
                                <th>{t("Node-RED Branch")}</th>
                                <td><code>/{gatewayData?.nodered_branch || "-"}</code></td>
                              </tr>
                              <tr>
                                <th>{t("Node-RED npm Packages")}</th>
                                <td>
                                  <ul>
                                    {gatewayData.npm_packages
                                      ?.split(',')
                                      ?.map(item => (
                                        <li key={item.trim()}>{item.trim()}</li>
                                      ))}
                                  </ul>
                                </td>
                              </tr>

                            </tbody>
                          </Table>

                        </Tab.Pane>

                        <Tab.Pane eventKey="third">
                          <Row>
                            <Col>
                              <div className="h4 mt-2 mb-4 tab-title">{t("Handshakes")}</div>
                            </Col>
                            <Col xs="auto" className="ms-auto text-end d-flex">
                              <Button
                                variant="outline-primary"
                                className="shadow-sm me-1 mt-2"
                                size="sm"
                                onClick={() => navigate("/gateways/" + id + "/handshakes")}>
                                <Activity className="feather" /> {isMobile ? "" : "Handshakes"}
                              </Button>
                            </Col>
                          </Row>
                          <Table size="sm" className="my-2">
                            <tbody>
                              <tr>
                                <th>{t("CPU Temperature")}</th>
                                <td>{gatewayData?.last_handshake == null ? "-" : gatewayData?.last_handshake.cpu_temp?.toLocaleString(i18n.language === "de" ? de : "en", {
                                  style: "decimal",
                                  maximumFractionDigits: 1
                                }) + " °C"}</td>
                              </tr>
                              <tr>
                                <th>{t("Disk Usage")}</th>
                                <td>{gatewayData?.last_handshake == null
                                  ? "-"
                                  : <ProgressBar
                                    className=""
                                    variant="primary"
                                    striped
                                    animated
                                    now={gatewayData?.last_handshake.disk_space}
                                    label={(gatewayData?.last_handshake.disk_space / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                                      style: 'percent',
                                      maximumFractionDigits: 0
                                    })}
                                  />}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("CPU Usage")}</th>
                                <td>{gatewayData?.last_handshake == null
                                  ? "-"
                                  : <ProgressBar
                                    className=""
                                    variant="primary"
                                    striped
                                    animated
                                    now={gatewayData?.last_handshake.cpu_usage}
                                    label={(gatewayData?.last_handshake.cpu_usage / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                                      style: 'percent',
                                      maximumFractionDigits: 0
                                    })}
                                  />}
                                </td>
                              </tr>


                              <tr>
                                <th>{t("RAM Usage")}</th>
                                <td>{gatewayData?.last_handshake == null
                                  ? "-"
                                  : <ProgressBar
                                    className=""
                                    variant="primary"
                                    striped
                                    animated
                                    now={gatewayData?.last_handshake.ram_usage}
                                    label={(gatewayData?.last_handshake.ram_usage / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                                      style: 'percent',
                                      maximumFractionDigits: 0
                                    })}
                                  />}
                                </td>
                              </tr>
                              <tr>
                                <th>{t("Uptime at last handshake")}</th>
                                <td>{gatewayData?.last_handshake?.uptime == null ? "-" : formatDuration(intervalToDuration({ start: 0, end: gatewayData?.last_handshake?.uptime * 1000 }), { locale: i18n.language === "de" ? de : null })}</td>
                              </tr>
                              <tr>
                                <th>{t("Asset Connection Protocol")}</th>
                                <td>
                                  <div>
                                    {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "opc_ua" && <img
                                      src={OPCUA_Logo}
                                      alt="avatar"
                                      className="me-3"
                                      width="auto"
                                      height={20}
                                    />}
                                    {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "mqtt" && <img
                                      src={mqtt_Logo}
                                      alt="avatar"
                                      className="me-3"
                                      width="auto"
                                      height={20}
                                    />}
                                    {gatewayData?.assets?.length > 0 && gatewayData?.assets[0]?.connection_protocol === "ads" && <img
                                      src={beckhoff_Logo}
                                      alt="avatar"
                                      className="me-3"
                                      width="auto"
                                      height={20}
                                    />}
                                    {/* <a>{gatewayData?.last_handshake?.asset_connected === true ? t("Connected") : t("Not connected")}</a> */}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>{t("Latest Speedtest")}</th>
                                <td>{gatewayData == null ? "n/a" : gatewayData?.latest_speedtest?.time == null ? "n/a" : format(parseISO(gatewayData?.latest_speedtest?.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                              </tr>
                              <tr>
                                <th>{t("Reliability (7 days)")}</th>
                                <td>{gatewayData == null ? "-" : gatewayData?.score?.handshake_reliability == null ? "-" : gatewayData?.score?.handshake_reliability.toLocaleString(i18n.language === "de" ? de : "en", {
                                  style: 'percent',
                                  maximumFractionDigits: 2,
                                })}</td>
                              </tr>
                              <tr>
                                <th>{t("Last missing handshake")}</th>
                                <td>{gatewayData?.last_missing_handshake?.expected_time ? format(parseISO(gatewayData?.last_missing_handshake?.expected_time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null }) : "-"}</td>
                              </tr>
                              <tr>
                                <th>{t("Drop-out span")}</th>
                                <td>{gatewayData?.last_missing_handshake?.gap_time ? isNaN(gatewayData?.last_missing_handshake?.gap_time) ? "-/-" : formatDuration(intervalToDuration({ start: 0, end: gatewayData?.last_missing_handshake?.gap_time * 1000 }), { locale: i18n.language === "de" ? de : null }) : "-"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Tab.Pane>

                        <Tab.Pane eventKey="actions">
                          <Row>
                            <Col>
                              <div className="h4 mt-2 mb-4 tab-title">{t("Remote Actions")}</div>
                            </Col>
                            <Col xs="auto" className="ms-auto text-end d-flex">
                            </Col>
                          </Row>
                          <Col md="12">
                            {isLoadingRemoteCall && <ShowLoadingSpinner />}

                            <Row>

                              <Col md="6">
                                <div className="h5 mt-4 mb-3">{t("Node-RED Trigger")}</div>
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  className="shadow-sm me-1 mb-1"
                                  onClick={(e) => handleTrigger(e, "GET", "knockknock")}>
                                  <Activity className="feather" /> {t("Nudge")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  className="shadow-sm me-1 mb-1"
                                  onClick={(e) => handleTrigger(e, "GET", "status")}>
                                  <Check className="feather" /> {t("Status")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "branch")}>
                                  <Gitlab className="feather" /> {t("Check Git Branch")}
                                </Button>
                                {/* <Button
                            variant="outline-primary"
                            className="shadow-sm me-1 mb-1"
                            onClick={(e) => handleTrigger(e, "GET", "test_opc")}>
                            <Code className="feather" /> {t("Check PLC connection")}
                          </Button> */}
                                {/* <Button
                            variant="outline-primary"
                            className="shadow-sm me-1 mb-1"
                            onClick={(e) => handleTrigger(e, "GET", "discover_opc_server")}>
                            <Code className="feather" /> {t("Discover OPC Server")}
                          </Button> */}
                                {/* <Button
                            variant="outline-primary"
                            className="shadow-sm me-1 mb-1"
                            onClick={(e) => handleTrigger(e, "GET", "ping_opc")}>
                            <Code className="feather" /> {t("Ping PLC")}
                          </Button> */}
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "show_config")}>
                                  <File className="feather" /> {t("Show configuration file")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "reload_config")}>
                                  <RefreshCw className="feather" /> {t("Reload configuration")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "send_handshake")}>
                                  <Activity className="feather" /> {t("Send Handshake")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "run_speedtest")}>
                                  <Compass className="feather" /> {t("Run speedtest")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "update_location")}>
                                  <Compass className="feather" /> {t("Test IP-location")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "send_usage_batch")}>
                                  <Clock className="feather" /> {t("Send /usage Batch")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "upload_failed_asset_logs")}>
                                  <Clock className="feather" /> {t("Send failed asset_logs")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "usage_logs")}>
                                  <DownloadCloud className="feather" /> {t("Get /usage_logs (JSON)")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={(e) => handleTrigger(e, "GET", "usage_cycles")}>
                                  <DownloadCloud className="feather" /> {t("Get /usage_cycles (JSON)")}
                                </Button>

                                {/* <div className="h5 mt-4 mb-3">Linux {t("Configuration")}</div>
                            <Button
                              variant="outline-primary"
                              className="shadow-sm me-1 mb-1"
                              size="sm"
                              onClick={() => window.confirm(t("An diesem Feature wird noch gearbeitet."))}>
                              {t("Update NR-Software")}
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="shadow-sm me-1 mb-1"
                              size="sm"
                              onClick={(e) => window.confirm("Use with caution. Are you sure?") && handleTrigger(e, "GET", "restart_container")}>
                              {t("Restart Docker Container")}
                            </Button> */}
                              </Col>
                              <Col md="6">
                                <div className="h5 mt-4 mb-3">{t("VPN Remote Access")}</div>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={() => window.confirm(t("Dies funktioniert nur, wenn Sie sich im cap-on VPN befinden.")) && handleButtonClick("http://" + gatewayData?.internal_ip + ":1880")}>
                                  {t("Open Node-RED Editor")}
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1 mb-1"
                                  size="sm"
                                  onClick={() => handleClipboard(`ssh admin@${gatewayData?.internal_ip}`)}
                                >
                                  {t("Copy ssh Command")}
                                </Button>

                                {/* <div className="h5 mt-4 mb-3">{t("Other")}</div>
                            <Button
                              variant="outline-primary"
                              className="shadow-sm me-1 mb-1"
                              size="sm"
                              onClick={() => navigate(`/application_logs/?username=${encodeURIComponent(`${gatewayData?.serial}@cap.on.de`)}`)}>
                              {t("Gateway Error Log")}
                            </Button> */}
                              </Col>
                            </Row>

                          </Col>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </>
        }
      </Container >
    </React.Fragment >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default GatewayDetails;
