import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Button, Col, Dropdown, Form, InputGroup, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";
import { Plus } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import AssetCategories from "../Helper/AssetCategories";
import UserDataManager from "../Helper/UserDataManager";
import { de } from "date-fns/locale";
import { getCoreBaseURL } from "../../config";
import UnitOptions from "../Helper/UnitOptions";

const SingleAssetModal = ({ selected_existing_asset, handleSave, handleCancel, contract_id }) => {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [title, setTitle] = useState();
  const [serial, setSerial] = useState();
  const [locationName, setLocationName] = useState();
  const [modelType, setModelType] = useState();
  const [PPUPrice, setPPUPrice] = useState();
  const [description, setDescription] = useState();
  const [gatewayId, setGatewayId] = useState();
  const [co2_kg_per_wh, set_co2_kg_per_wh] = useState(0);
  const [duration, setDuration] = useState(60);
  const [monthlyPlannedUsage, setMonthlyPlannedUsage] = useState(0);
  // const [monthlyBaseFee, setMonthlyBaseFee] = useState(0);
  const [activationDate, setActivationDate] = useState();
  const [commissioning_date, set_commissioning_date] = useState();
  const [build_date, set_build_date] = useState();

  const [depreciationCategory, setDepreciationCategory] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [maxOutputPerHour, setMaxOutputPerHour] = useState();
  const [dailyOperatingHours, setDailyOperatingHours] = useState();
  const [usage_enabled, set_usage_enabled] = useState();
  const [usage_factor, set_usage_factor] = useState();
  const [billing_unit_type, set_billing_unit_type] = useState();
  const [anomaly_report_instructions, set_anomaly_report_instructions] = useState();
  const [assetData, setAssetData] = useState([]);
  // const [assetFinancials, setAssetFinancials] = useState();
  const [connectionProtocol, setConnectionProtocol] = useState([]);

  // Financials
  // const [financials_base_price, set_financials_base_price] = useState(0);
  const [financials_tier, set_financials_tier] = useState();
  const [financials_investment_price, set_financials_investment_price] = useState();
  const [financials_monthly_base_fee, set_financials_monthly_base_fee] = useState();
  // const [financials_market_value, set_financials_market_value] = useState(0);
  // const [financials_residual_book_value, set_financials_residual_book_value] = useState(0);
  // const [financials_capon_interest_rate, set_financials_capon_interest_rate] = useState(0);
  const [financials_capon_rate, set_financials_capon_rate] = useState();
  // const [financials_full_interest_rate, set_financials_full_interest_rate] = useState(0);
  // const [financials_refi_interest_rate, set_financials_refi_interest_rate] = useState(0);
  const [financials_refi_rate, set_financials_refi_rate] = useState();
  const [financials_starting_fee, set_financials_starting_fee] = useState();
  const [financials_price_factors, set_financials_price_factors] = useState([]);

  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    if (selected_existing_asset) {
      let body = {
        "title": title,
        "serial": serial,
        "machine_model": modelType,
        "location_name": locationName,
        // "investment_price": investment_price,
        "per_use_price": PPUPrice,
        "activation_date": activationDate,
        "description": description,
        "duration_in_months": duration,
        "connection_protocol": connectionProtocol,
        "sending_interval_minutes": sending_interval_minutes,
        "daily_operating_hours": dailyOperatingHours,
        "anomaly_report_instructions": anomaly_report_instructions,
        "max_output_per_hour": maxOutputPerHour,
        "co2_kg_per_wh": co2_kg_per_wh,
        "usage_enabled": usage_enabled,
        "usage_factor": usage_factor,
        "commissioning_date": commissioning_date,
        "build_date": build_date,
        "billing_unit_type": billing_unit_type,
        "iot_gateway_id": gatewayId,
        "category": depreciationCategory,
        "timezone": selectedTimeZone
      };

      // Add location only if latitude and longitude are not null or undefined
      if (latitude === "" || longitude === "") {
        body.location = null;
      } else if (latitude != null && longitude != null) {
        body.location = {
          "type": "Point",
          "coordinates": [latitude, longitude]
        };
      }

      console.log("----->", body)

      axios
        .put("/assets/" + selected_existing_asset, body)
        .then(function (response) {
          // handle success
          console.log(response.status, "/assets");

          notyf.open({
            type: "success",
            message: t("Item \"") + (title || assetData?.title) + t("\" updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleSave();
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });

      let bodyFinancials = {
        "tier": parseFloat(financials_tier),
        "price_factors": financials_price_factors,
        "refi_rate": parseFloat(financials_refi_rate),
        "capon_rate": parseFloat(financials_capon_rate),
        "starting_fee": parseFloat(financials_starting_fee),
        "investment_price": parseFloat(financials_investment_price),
        "monthly_base_fee": parseFloat(financials_monthly_base_fee),
        // "market_value": financials_market_value,
        // "residual_book_value": financials_residual_book_value
        // "base_price": financials_base_price,
        // "full_interest_rate": financials_full_interest_rate,
        // "refi_interest_rate": financials_refi_interest_rate,
        // "capon_interest_rate": financials_capon_interest_rate,
      }

      console.log("----->", bodyFinancials)
      axios
        .put("/assets/" + selected_existing_asset + "/financials", bodyFinancials)
        .then(function (response) {
          // handle success
          console.log(response.status, "/financials");
        })
        .catch(function (error) {
          // handle error
          // setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    } else {

      axios
        .post("/assets/", {
          "title": title,
          "serial": serial,
          "machine_model": modelType,
          "location_name": locationName,
          "per_use_price": PPUPrice,
          // "monthly_base_fee": monthlyBaseFee,
          // "investment_price": assetPrice,
          "activation_date": activationDate,
          "description": description,
          "monthly_planned_usages": monthlyPlannedUsage,
          "duration_in_months": duration,
          "co2_kg_per_wh": co2_kg_per_wh,
          "daily_operating_hours": dailyOperatingHours,
          "max_output_per_hour": maxOutputPerHour,
          "iot_gateway_id": gatewayId,
          "usage_enabled": usage_enabled,
          "usage_factor": usage_factor,
          "billing_unit_type": billing_unit_type,
          "category": depreciationCategory,
          "location": {
            "type": "Point",
            "coordinates": [
              latitude, longitude
            ]
          },
          "timezone": selectedTimeZone,
          "contract_id": contract_id,
          "financials": (financials_tier === 1 || financials_tier === 2) ?
            {
              "tier": financials_tier,
              "price_factors": financials_price_factors,
              "refi_rate": financials_refi_rate,
              "capon_rate": financials_capon_rate,
              "starting_fee": financials_starting_fee,
              "investment_price": financials_investment_price,
              "monthly_base_fee": financials_monthly_base_fee,
              // "market_value": financials_market_value,
              // "residual_book_value": financials_residual_book_value
              // "full_interest_rate": financials_full_interest_rate,
              // "refi_interest_rate": financials_refi_interest_rate,
              // "capon_interest_rate": financials_capon_interest_rate,
              // "base_price": financials_base_price,
            } : null
        })
        .then(function (response) {
          // handle success
          navigate("/assets/" + response.data.id);
          console.log(response.status, "financials");

          notyf.open({
            type: "success",
            message: t("New item \"") + title + t("\" has been added."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
        });
    }
  }

  const [GatewayArray, setGatewayArray] = useState([]);

  useEffect(() => {

    setError({ showMessage: false, errorData: null })

    if (selected_existing_asset) {
      axios
        .get("/assets/" + selected_existing_asset)
        .then(function (response) {
          // handle success
          console.log(response.status, "/asset");
          setAssetData(response.data);

          setConnectionProtocol(response.data?.connection_protocol)
          setSelectedTimeZone(response.data?.timezone)
          setGatewayId(response.data?.iot_gateway_id)
          setDepreciationCategory(response.data?.category)
          set_co2_kg_per_wh(response.data?.co2_kg_per_wh)
          set_usage_enabled(response.data?.usage_enabled)
          set_billing_unit_type(response.data?.billing_unit_type)
          setLatitude(response.data?.location?.coordinates[0])
          setLongitude(response.data?.location?.coordinates[1])
          setPPUPrice(response.data?.per_use_price)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/assets/" + selected_existing_asset + "/financials")
        .then(function (response) {
          // handle success
          console.log(response.status, "Loaded financials");
          // setAssetFinancials(response.data);

          set_financials_price_factors(response.data?.price_factors)
          set_financials_tier(response.data?.tier)
          set_financials_monthly_base_fee(response.data?.monthly_base_fee)
          set_financials_investment_price(response.data?.investment_price)
          set_financials_starting_fee(response.data?.starting_fee)
          set_financials_refi_rate(response.data?.refi_rate)
          set_financials_capon_rate(response.data?.capon_rate)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      set_financials_price_factors()
      set_financials_tier()
      set_financials_monthly_base_fee()
      set_financials_investment_price()
      set_financials_starting_fee()
      set_financials_refi_rate()
      set_financials_capon_rate()
    }

    axios
      .get("/iot_gateways/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/gateways");
        setGatewayArray(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(getCoreBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "/openapi");
        setConnectionProtocolOptions(response.data["components"]["schemas"]["AssetConnectionProtocol"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    const zoneNames = new Set();
    const names = Intl.supportedValuesOf('timeZone');
    names.forEach(name => zoneNames.add(name));
    setTimeZones([...zoneNames]);

  }, []);

  const loadGateways = () => {
    return GatewayArray.map((gateway) => {
      return (
        <option key={gateway.id} value={gateway.id}>{gateway.serial}</option>
      );
    });
  };

  var factors = [];
  function handleChangeP2Produced(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = financials_price_factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["starting_from"] = parseFloat(event.target.value);
    } else {
      factors[event.target.id]["starting_from"] = parseFloat(event.target.value);
    }

    set_financials_price_factors(factors)

    console.log(financials_price_factors);
  }

  function handleChangeP2Factor(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = financials_price_factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["price"] = parseFloat(event.target.value);
    } else {
      factors[event.target.id]["price"] = parseFloat(event.target.value);
    }

    set_financials_price_factors(factors)

    console.log(financials_price_factors);
  }

  const [ConnectionProtocolOptions, setConnectionProtocolOptions] = useState([]);
  const [sending_interval_minutes, set_sending_interval_minutes] = useState();

  const ShowConnectionProtocolOptions = () => {
    return ConnectionProtocolOptions.map((item) => {
      return (
        <option key={item} value={item}>{t(item)}</option>
      );
    });
  };

  const renderRows = () => {

    return financials_price_factors?.map((entry, index) => {
      return (
        <Row key={index}>
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Produced}
                  id={index}
                  type="number"
                  defaultValue={entry.starting_from?.toLocaleString(i18n.language === "de" ? de : "en")}
                  placeholder="0 units" />
                <InputGroup.Text>{t("Units")}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>

          {/* <Col md={1}></Col> */}
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Factor}
                  id={index}
                  type="number"
                  defaultValue={entry.price}
                  placeholder="0 €" />
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      );
    });
  };

  const GEOCODING_API_KEY = process.env.REACT_APP_MAPS_API_KEY;

  // Function to fetch coordinates based on locationName
  const fetchCoordinates = async () => {
    if (!locationName) return;

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(locationName)}&key=${GEOCODING_API_KEY}`
      );
      const data = await response.json();

      console.log("--->", data)
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        setLatitude(location.lat);
        setLongitude(location.lng);
      } else {
        notyf.open({
          type: "error",
          message: t("Unable to fetch coordinates. Please check the address."),
          duration: "4500",
          dismissible: true,
          position: { x: "right", y: "bottom" }
        });
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      notyf.open({
        type: "error",
        message: t("Error fetching coordinates."),
        duration: "4500",
        dismissible: true,
        position: { x: "right", y: "bottom" }
      });
    }
  };

  return (
    <Modal size="xl"
      show
      onHide={handleCancel}
      style={{ zIndex: 1060 }} // Higher z-index to ensure it appears above the first modal
    >
      <Modal.Header closeButton>{t("Create asset")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <h5>{t("General information")}</h5>
          <br />
          <Row>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Title")}</Form.Label>
                    <Form.Control required onChange={(e) => setTitle(e.target.value)} defaultValue={assetData?.title} placeholder={t("Title")} />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Model Type")}</Form.Label>
                    <Form.Control onChange={event => setModelType(event.target.value)} defaultValue={assetData?.machine_model} placeholder="ABC 123 DEF" />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Serial No.")}</Form.Label>
                    <Form.Control onChange={(e) => setSerial(e.target.value)} defaultValue={assetData?.serial} placeholder="ABC 123 DEF" />
                  </Form.Group>

                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Description")}</Form.Label>
                <Form.Control
                  onChange={(e) => setDescription(e.target.value)}
                  rows="9"
                  as="textarea"
                  defaultValue={assetData?.description}
                  placeholder={t("Description")} />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>{t("Manufacturing date")}</Form.Label>
                <Form.Control
                  onChange={(e) => set_build_date(parseISO(e.target.value))}
                  type="date"
                  defaultValue={assetData?.build_date == null ? null : format(parseISO(assetData?.build_date), "yyyy-MM-dd", { locale: i18n.language === "de" ? de : null })}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>{t("Commissioning date")}</Form.Label>
                <Form.Control
                  onChange={(e) => set_commissioning_date(parseISO(e.target.value))}
                  type="date"
                  defaultValue={assetData?.commissioning_date == null ? null : format(parseISO(assetData?.commissioning_date), "yyyy-MM-dd", { locale: i18n.language === "de" ? de : null })}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <h5>{t("Location")}</h5>
          <br />
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Location")}</Form.Label>
                <InputGroup>
                  <Form.Control
                    onChange={(e) => setLocationName(e.target.value)}
                    // value={locationName}
                    defaultValue={assetData?.location_name}
                    placeholder="Paris, France"
                  />
                  <Button variant="outline-secondary" onClick={fetchCoordinates}>
                    {t("Get Coordinates")}
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Time zone")}</Form.Label>
                <Form.Select onChange={(e) => setSelectedTimeZone(e.target.value)}>
                  <option>{t("-- Select --")}</option>
                  {timeZones.map((zone) => (
                    <option key={zone} value={zone} selected={zone === selectedTimeZone}>
                      {zone}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Latitude")}</Form.Label>
                <Form.Control onChange={(e) => setLatitude(e.target.value)} value={latitude} placeholder="20.3082" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Longitude")}</Form.Label>
                <Form.Control onChange={(e) => setLongitude(e.target.value)} value={longitude} placeholder="51.2980" />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <h5>{t("Technical Settings")}</h5>
          <br />
          <Row>
            <Col md="4">
              <Form.Group className="mb-3">
                <Form.Label>{t("cap-on IoT-Gateway")}</Form.Label>
                <Form.Select name="state" onChange={(e) => setGatewayId(e.target.value)} value={gatewayId}>
                  <option>{t("-- Select --")}</option>
                  {loadGateways()}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("connection_protocol")}</Form.Label>
                <Form.Select name="state" onChange={event => setConnectionProtocol(event.target.value)} value={connectionProtocol}>
                  <option value="">{t("-- Select --")}</option>
                  {ShowConnectionProtocolOptions()}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group className="mb-3">
                <Form.Label>{t("sending_interval_minutes")}</Form.Label>
                <Form.Control onChange={event => set_sending_interval_minutes(event.target.value)} defaultValue={assetData?.sending_interval_minutes} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={usage_enabled ? "4" : "12"}>
              <Form.Group className="mb-3">
                <Form.Label>{t("usage_enabled")}</Form.Label>
                <Form.Check
                  checked={usage_enabled}
                  onChange={() => set_usage_enabled(!usage_enabled)}
                  defaultValue={usage_enabled}
                  label={t("Enabled")}
                  type="checkbox" />
              </Form.Group>
            </Col>
            {usage_enabled && <Col md="4">
              <Form.Group className="mb-3">
                <Form.Label>{t("usage_factor")}</Form.Label>
                <Form.Control
                  onChange={event => set_usage_factor(event.target.value)}
                  type="decimal"
                  defaultValue={assetData?.usage_factor} />
              </Form.Group>
            </Col>}
            {usage_enabled && <Col md="4">
              <Form.Group className="mb-3">
                <Form.Label>{t("billing_unit_type")}</Form.Label>
                <Form.Select
                  name="state"
                  value={billing_unit_type}
                  onChange={event => set_billing_unit_type(event.target.value)}
                >
                  <UnitOptions />

                </Form.Select>
              </Form.Group>
            </Col>}

          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Anomaly Report-Instructions")}</Form.Label>
                <Form.Control
                  onChange={event => set_anomaly_report_instructions(event.target.value)}
                  defaultValue={assetData?.asset_anomaly_report_instructions}
                  rows="9"
                  as="textarea"
                  placeholder="" />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <h5>{t("Planned Production")}</h5>
          <br />
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Maximum Output/h")}</Form.Label>
                <Form.Control onChange={e => setMaxOutputPerHour(e.target.value)} defaultValue={assetData?.max_output_per_hour} placeholder="20/h" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Daily Operating Hours")}</Form.Label>
                <Form.Control onChange={e => setDailyOperatingHours(e.target.value)} defaultValue={assetData?.daily_operating_hours} placeholder="22 (daily)" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("kg CO\u2082 per Wh")} ({t("Plan")})</Form.Label>
                <Form.Select onChange={(e) => set_co2_kg_per_wh(e.target.value)} value={co2_kg_per_wh}>
                  <option>{t("-- Select --")}</option>
                  <option value={0.000420}>{t("Strommix")}</option>
                  <option value={0.000025}>{t("Ökostrom")}</option>
                  <option value={0.001}>{t("Kohle")}</option>
                  <option value={0.0005}>{t("Gas")}</option>
                  <option value={0.00065}>{t("Öl")}</option>
                  <option value={0.00002}>{t("Kernkraft")} </option>
                  <option value={0.000024}>{t("Wasser")} </option>
                  <option value={0.000011}>{t("Wind")}</option>
                  <option value={0.000045}>{t("Solar")}</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>{t("kg CO\u2082 per Wh")}</Form.Label>
                <Form.Control value={co2_kg_per_wh ? co2_kg_per_wh : 0.42} onChange={(e) => set_co2_kg_per_wh(e.target.value)} placeholder="0.00042 kg CO\u2082" />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>{t("kg CO\u2082 per kWh")}</Form.Label>
                <Form.Control disabled value={(co2_kg_per_wh ? co2_kg_per_wh : 0.42) * 1000} placeholder="0.42 kg CO\u2082" />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Category")}</Form.Label>
                <Form.Select name="state" onChange={(e) => setDepreciationCategory(e.target.value)} defaultValue={depreciationCategory}>
                  <option>{t("-- Select --")}</option>
                  <AssetCategories />
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>{t("cap-on Activation Date (start of billing cycle, SOP, etc.)")}</Form.Label>
                <Form.Control
                  onChange={(e) => setActivationDate(parseISO(e.target.value))}
                  type="date"
                  defaultValue={assetData.activation_date == null ? null : format(parseISO(assetData.activation_date), "yyyy-MM-dd", { locale: i18n.language === "de" ? de : null })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Duration in months")}</Form.Label>
                <Form.Control onChange={(e) => setDuration(e.target.value)} placeholder={"60 " + t("months")} defaultValue={assetData?.duration_in_months} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Monthly Planned Production")}</Form.Label>
                <Form.Control onChange={(e) => setMonthlyPlannedUsage(e.target.value)} defaultValue={assetData?.monthly_planned_usages} placeholder={t("250 units/month")} />
              </Form.Group>
            </Col>
          </Row>

          {
            UserDataManager.getInstance().isCapOn() &&
            <>
              <br />
              <br />
              <hr />
              <h5>{t("Financial Asset Settings")}</h5>
              <br />
              <Accordion>
                <Accordion.Item eventKey="0" className="bg-white">
                  <Accordion.Header>{t("Financial Asset Settings")}</Accordion.Header>
                  <Accordion.Body className="bg-white">
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_tier")}</Form.Label>
                          <Form.Select required onChange={(e) => set_financials_tier(e.target.value)} value={financials_tier}>
                            <option>{t("-- Select --")}</option>
                            {/* <option value={0}>{t("Tier 0")}</option> */}
                            <option value={1}>{t("Tier 1 - Pay-per-Use Leasing")}</option>
                            <option value={2}>{t("Tier 2 - Asset-as-a-Service Light")}</option>
                            <option value={3}>{t("Tier 3 - Asset-as-a-Service")}</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_monthly_base_fee")}</Form.Label>
                          <Form.Control type="number" onChange={event => set_financials_monthly_base_fee(event.target.value)} defaultValue={financials_monthly_base_fee} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_investment_price")}</Form.Label>
                          <Form.Control type="number" onChange={event => set_financials_investment_price(event.target.value)} defaultValue={financials_investment_price} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_base_price")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_base_price(event.target.value)} 
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("Static Pay-per-Use Price (Tier 3 only)")}</Form.Label>
                          <Form.Control
                            onChange={(e) => setPPUPrice(e.target.value)}
                            type="number"
                            defaultValue={PPUPrice}
                            placeholder="120.50 €" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_starting_fee")}</Form.Label>
                          <Form.Control type="number" onChange={event => set_financials_starting_fee(event.target.value)} defaultValue={financials_starting_fee} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_refi_rate")}</Form.Label>
                          <Form.Control type="number" onChange={event => set_financials_refi_rate(event.target.value)} defaultValue={financials_refi_rate} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_capon_rate")}</Form.Label>
                          <Form.Control type="number" onChange={event => set_financials_capon_rate(event.target.value)} defaultValue={financials_capon_rate} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_residual_book_value")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_residual_book_value(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_full_interest_rate")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_full_interest_rate(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_capon_interest_rate")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_capon_interest_rate(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_refi_interest_rate")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_refi_interest_rate(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("financials_market_value")}</Form.Label>
                          <Form.Control
                            disabled
                          // onChange={event => set_financials_market_value(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Accordion>
                        <Accordion.Item eventKey="0" className="bg-white">
                          <Accordion.Header>{t("Pay-per-Use Price Factors")}</Accordion.Header>
                          <Accordion.Body>
                            <Form>
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Starting From Unit")}</Form.Label>
                                  </Form.Group>
                                </Col>
                                {/* <Col md={1}> </Col>*/}
                                <Col md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Unit Price")}</Form.Label>
                                  </Form.Group>
                                </Col>
                              </Row>
                              {renderRows()}
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          }
          <br />
          <br />
          <hr />
          <br />
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button variant={"outline-secondary"} onClick={handleCancel} >
                {t("Cancel")}
              </Button>
              <Button variant={"primary"} className="ms-1" type="submit">
                {selected_existing_asset ? t("Apply") : t("Create")}
              </Button>
            </Col>
          </Row>
        </Form >
      </Modal.Body >
    </Modal >
  );
};

export default SingleAssetModal;
