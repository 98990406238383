import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
//import Settings from "../components/Settings";
//import AuthGuard from "../components/guards/AuthGuard";
// import dashboardItems from "../components/sidebar/dashboardItems";
import menuItemsCap_On_Admin from "../components/sidebar/dashboardItems-cap-on-Admin";
import menuItemsCap_On_Agent from "../components/sidebar/dashboardItems-cap-on-Agent";
import menuItemsRegular from "../components/sidebar/dashboardItems-RegularServiceFocus";
import menuItemsRegularCustomerFocus from "../components/sidebar/dashboardItems-RegularCustomerFocus";
import menuItemsRegularServiceFocus from "../components/sidebar/dashboardItems-RegularServiceFocus";
import menuItemsRegularFinanceFocus from "../components/sidebar/dashboardItems-RegularFinanceFocus"
import menuItemsRegularTenantAdmin from "../components/sidebar/dashboardItems-Regular-Tenant-Admin";
import ReactGA from 'react-ga';
import UserDataManager from "../pages/Helper/UserDataManager";
import { useTranslation } from "react-i18next";
import { getIAMBaseURL, isProdEnvironment } from "../config";

const DashboardLayout = ({ children }) => {

  if (isProdEnvironment()) {
    ReactGA.initialize("UA-219321567-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log("GA running!")
  } else {
    console.log("GA not running!", "You are running in... ", process.env.REACT_APP_ENVIRONMENT)
  }

  const [data, setData] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {

    // console.log("...DashboardLayout...", UserDataManager.getInstance().getUserData().id)

    if (UserDataManager.getInstance().getUserData().id === undefined) {
      axios
        .get(getIAMBaseURL() + "/users/me")
        .then(function (response) {
          // handle success
          console.log(response.status, "/users/me-2");
          UserDataManager.getInstance().setUserData(response.data);
          updateMenu(response.data.role);
          i18n.changeLanguage(response.data.preferred_language)
        })
        .catch(function (error) {
          // handle error
          // setError({ showMessage: true, errorData: error })
        });
    } else {
      console.log("UserData already set (2)");  // UserDataManager.getInstance().getUserData().id
      i18n.changeLanguage(UserDataManager.getInstance().getUserData().preferred_language)
      updateMenu(UserDataManager.getInstance().getUserData().role);
    }
  }, []);


  async function updateMenu(role) {

    console.log("Updating menu for role:", role);

    const focus = await UserDataManager.getInstance().getFocus(); // Wait for the async operation
    console.log("----------> permissions:", focus);


    switch (role) {
      case "admin":
        setData(menuItemsCap_On_Admin)
        break;
      case "cap-on agent":
        setData(menuItemsCap_On_Agent)
        break;
      case "regular":

        const focusSet = new Set(focus); // Convert focus into a Set for easier lookup

        if (focusSet.has("finance_agent")) {
          setData(UserDataManager.getInstance().isTenantAdmin() ? menuItemsRegularTenantAdmin : menuItemsRegularFinanceFocus);
        } else if (focusSet.has("service_agent")) {
          setData(UserDataManager.getInstance().isTenantAdmin() ? menuItemsRegularTenantAdmin : menuItemsRegularServiceFocus);
        } else if (focusSet.has("client_agent")) {
          setData(UserDataManager.getInstance().isTenantAdmin() ? menuItemsRegularTenantAdmin : menuItemsRegularCustomerFocus);
        } else {
          setData(UserDataManager.getInstance().isTenantAdmin() ? menuItemsRegularTenantAdmin : menuItemsRegular);
        }

        break;
      // case "client agent":
      //   setData(menuItemsManufacturers_Agent)
      //   break;

      // case "manufacturer agent":
      //   setData(menuItemsManufacturers_Agent)
      //   break;

      // case "finance agent":
      //   setData(menuItemsFinance_Agent)
      //   break;
      default:
        setData(menuItemsRegular)
        break;
    }
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={data} showFooter={true} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  )
};

export default DashboardLayout;
