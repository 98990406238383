import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLogsBaseURL } from "../../config";
import { useTranslation } from "react-i18next";

function UnitOptions(props) {

  const { t, i18n } = useTranslation();

  return (
    <>
      <option value="">{t("-- Select --")}</option>

      <option value={"state"}>{t("Individual")}</option>

      <optgroup label={t("Relative")}>
        <option value={"%"}>{t("%")}</option>
        <option value={"%_100"}>{t("% (* 100)")}</option>
      </optgroup>

      <optgroup label={t("Pressure")}>
        <option value={"bar"}>{t("bar")}</option>
        <option value={"Pa"}>{t("Pascal (Pa)")}</option>
        <option value={"psi"}>{t("Pounds per square inch (psi)")}</option>
      </optgroup>

      <optgroup label={t("Temperature")}>
        <option value={"°C"}>{t("Celsius (°C)")}</option>
        <option value={"°F"}>{t("Fahrenheit (°F)")}</option>
        <option value={"K"}>{t("Kelvin (K)")}</option>
      </optgroup>

      <optgroup label={t("Energy/Power")}>
        <option value={"A"}>{t("Current (Ampere)")}</option>
        <option value={"V"}>{t("Voltage (Volt)")}</option>
        <option value={"W"}>{t("Watt (W)")}</option>
        <option value={"kW"}>{t("Kilowatt (kW)")}</option>
        <option value={"Wh"}>{t("Watt-hour (Wh)")}</option>
        <option value={"kWh"}>{t("Kilowatt-hour (kWh)")}</option>
        <option value={"VA"}>{t("Voltampere (VA)")}</option>
        <option value={"N·m"}>{t("Newton meter (N·m)")}</option>
        <option value={"J"}>{t("Joule (J)")}</option>
      </optgroup>

      <optgroup label={t("Volume")}>
        <option value={"l"}>{t("Liters (l)")}</option>
        <option value={"ml"}>{t("Milliliters (ml)")}</option>
        <option value={"gal"}>{t("Gallons (gal)")}</option>
        <option value={"m³"}>{t("Cubic meter (m³)")}</option>
        <option value={"cm³"}>{t("Cubic centimeter (cm³)")}</option>
        <option value={"ft³"}>{t("Cubic feet (ft³)")}</option>
        <option value={"m³/h"}>{t("Mass flow (m³/h)")}</option>
        <option value={"kg/h"}>{t("Mass flow (kg/h)")}</option>
      </optgroup>

      <optgroup label={t("Distance/Length")}>
        <option value={"nm"}>{t("Nanometer (nm)")}</option>
        <option value={"µm"}>{t("Mikrometer (µm)")}</option>
        <option value={"mm"}>{t("Millimeter (mm)")}</option>
        <option value={"cm"}>{t("Centimeter (cm)")}</option>
        <option value={"m"}>{t("Meter (m)")}</option>
        <option value={"km"}>{t("Kilometer (km)")}</option>
        <option value={"mi"}>{t("Mile (mi)")}</option>
        <option value={"in"}>{t("Inch (in)")}</option>
        <option value={"ft"}>{t("Feet (ft)")}</option>
      </optgroup>

      <optgroup label={t("Time")}>
        <option value={"s"}>{t("Seconds (s)")}</option>
        <option value={"ds"}>{t("Deciseconds (ds)")}</option>
        <option value={"min"}>{t("Minutes (min)")}</option>
        <option value={"h"}>{t("Hours (h)")}</option>
        <option value={"d"}>{t("Days (d)")}</option>
        <option value={"w"}>{t("Weeks (w)")}</option>
        <option value={"mth"}>{t("Months (mth)")}</option>
        <option value={"y"}>{t("Years (y)")}</option>
        <option value={"1/10s"}>{t("1/10s")}</option>
      </optgroup>

      <optgroup label={t("Velocity/Speed")}>
        <option value={"m/s"}>{t("Meters per second (m/s)")}</option>
        <option value={"km/h"}>{t("Kilometers per hour (km/h)")}</option>
        <option value={"mi/h"}>{t("Miles per hour (mi/h)")}</option>
        <option value={"r/min"}>{t("Revolutions per minute (r/min)")}</option>
      </optgroup>

      <optgroup label={t("Weight/Mass")}>
        <option value={"g"}>{t("Grams (g)")}</option>
        <option value={"kg"}>{t("Kilograms (kg)")}</option>
        <option value={"lb"}>{t("Pounds (lb)")}</option>
        <option value={"t"}>{t("Tons (t)")}</option>
        <option value={"oz"}>{t("Ounces (oz)")}</option>
      </optgroup>
    </>
  );

};

export default UnitOptions;
