import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import ReactJson from 'react-json-view';
import DigitalTwinCard from "../AssetDigitalTwin/DigitalTwinCard";
import DefaultTwinStructure from "../AssetDigitalTwin/DigitalTwinStructures/DigitalTwinStructureDefault.json"
import { Clipboard, Plus, X } from "react-feather";
import usePalette from "../../../hooks/usePalette";
import UserDataManager from "../../Helper/UserDataManager";
// import DigitalTwinDesigner from "./DigitalTwinDesigner";

const SingleTwinDesignerModal = ({ show, asset_id, configData, handleClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [twinJSON, setTwinJSON] = useState("{}");
  const [jsonOutput, setJsonOutput] = useState("");

  useEffect(() => {
    if (show && configData) {

      // console.log("---->", JSON.stringify(configData?.twin_structure, null, 2))
      try {
        // Convert the twin_structure object to a JSON string and set it in the state
        setTwinJSON(JSON.stringify(configData?.twin_structure, null, 2));
      } catch (error) {
        console.error("Error parsing twin_structure", error);
        setTwinJSON("{}"); // Optionally set an empty JSON object
      }
    } else {
      setTwinJSON("{}");
    }
  }, [show, configData]);

  function handleUpdate(e) {
    e.preventDefault();

    let twinStructure;
    try {
      twinStructure = jsonOutput; // Directly use the updated jsonOutput
    } catch (error) {
      console.error("Invalid JSON format", error);
      setError({ showMessage: true, errorData: "Invalid JSON format" });
      return;
    }

    axios
      .put(`/assets/${asset_id}/iot_configs/`, { twin_structure: twinStructure })
      .then(response => {
        console.log(response.status, "/iot_configs PUT");
        handleClose();
      })
      .catch(error => {
        console.log(error);
        setError({ showMessage: true, errorData: error });
      });
  }

  let parsedJSON;
  try {
    parsedJSON = JSON.parse(twinJSON);
  } catch (error) {
    parsedJSON = {}; // Set to empty object if parsing fails
  }

  const extractPLCNodeIds = (structure) => {
    let plcNodeIds = [];

    const traverse = (node) => {

      if (node && node?.plc_node_id) {
        plcNodeIds.push(node?.plc_node_id);
      }

      // Traverse "children" if they exist
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => traverse(child));
      }

      // Traverse "tabs" if they exist
      if (node.tabs && node.tabs.length > 0) {
        node.tabs.forEach(tab => traverse(tab));
      }
    };

    traverse(structure);
    return plcNodeIds;
  };

  const DigitalTwinDesigner = ({ configData, onJsonChange, ...props }) => {
    const { t } = useTranslation();
    const palette = usePalette();

    // Initialize structure from configData?.twin_structure or with default structure
    const [structure, setStructure] = useState(configData?.twin_structure || {
      type: 'TabContainer',
      defaultActiveKey: 'overview',
      tabs: [
        {
          eventKey: 'overview',
          title: 'Overview',
          iconUrl: '',
          backgroundImageUrl: '',
          children: [
            { type: 'Row', children: [{ type: 'Col', size: 6, children: [] }] },
          ],
        },
      ],
    });

    const runDesigner = () => {
      if (onJsonChange) {
        onJsonChange(structure); // Pass the updated JSON structure back up
      }
    };

    const [activeTab, setActiveTab] = useState(structure.defaultActiveKey || 'overview');
    const [showModal, setShowModal] = useState(false);

    const columnSizes = [2, 3, 4, 6, 8, 9, 12];

    useEffect(() => {
      if (configData?.twin_structure) {
        setStructure(configData.twin_structure);
        setActiveTab(configData.twin_structure.defaultActiveKey || 'overview');
      }
    }, [configData?.twin_structure]);

    const addTab = () => {
      const newEventKey = `tab-${structure.tabs.length + 1}`;
      const newTab = {
        eventKey: newEventKey,
        title: `New Tab ${structure.tabs.length + 1}`,
        iconUrl: '',
        backgroundImageUrl: '',
        children: [
          { type: 'Row', children: [{ type: 'Col', size: 6, children: [] }] },
        ],
      };

      setStructure((prev) => ({
        ...prev,
        tabs: [...prev.tabs, newTab],
      }));
      setActiveTab(newEventKey);
    };

    const removeTab = (eventKey) => {
      setStructure((prev) => ({
        ...prev,
        tabs: prev.tabs.filter((tab) => tab.eventKey !== eventKey),
      }));
      if (activeTab === eventKey && structure.tabs.length > 1) {
        setActiveTab(structure.tabs[0].eventKey);
      }
    };

    const updateTabProperty = (eventKey, key, value) => {
      setStructure((prev) => {
        const updatedTabs = prev.tabs.map((tab) =>
          tab.eventKey === eventKey ? { ...tab, [key]: value } : tab
        );
        return { ...prev, tabs: updatedTabs };
      });
    };

    const addChildToNode = (node, type) => {
      let newChild;

      if (type === 'Row') {
        newChild = {
          type: 'Row',
          children: [] // No default column, initialize with an empty array
        };
      } else if (type === 'DataPointCard') {
        newChild = {
          type: 'DataPointCard',
          plc_node_id: '',
          alwaysShow: false,
          description: ''
        };
      } else if (type === 'DataPointCardLastUsageCycle') {
        newChild = {
          type: 'DataPointCardLastUsageCycle'
        };
      } else {
        newChild = {
          type,
          plc_node_id: '',
          alwaysShow: false,
          children: [],
        };
      }

      if (!node.children) {
        node.children = [];
      }
      node.children.push(newChild);
    };

    const addChild = (type, parentNode = null) => {
      setStructure((prev) => {
        const updatedTabs = prev.tabs.map((tab) => {
          if (tab.eventKey === activeTab) {
            if (parentNode) {
              addChildToNode(parentNode, type);
            } else {
              tab.children.push({ type, plc_node_id: '', alwaysShow: false, children: [] });
            }
          }
          return tab;
        });
        return { ...prev, tabs: updatedTabs };
      });
    };

    const removeChildFromNode = (parent, childIndex) => {
      parent.children.splice(childIndex, 1);
      setStructure({ ...structure });
    };

    const updateColumnSize = (parent, index, newSize) => {
      parent.children[index].size = newSize;
      setStructure({ ...structure });
    };

    const renderComponent = (node, parent = null, index = null) => {
      switch (node.type) {
        case 'Row':
          return (
            <Row style={{ backgroundColor: palette["gray-200"], border: '1px dashed #ddd', padding: '5px', margin: '10px 0' }}>
              {node.children?.map((child, childIndex) => (
                <Col key={childIndex} md={child.size || 6} style={{ padding: '5px' }}>
                  {renderComponent(child, node, childIndex)}
                </Col>
              ))}
              <div style={{ marginTop: '10px' }}>
                <Button variant="outline-primary" size="sm" onClick={() => addChild('Col', node)} style={{ margin: '5px' }}>
                  <Plus className='feather' /> {t("Column")}
                </Button>
                <Button variant="outline-primary" size="sm" onClick={() => addChild('DataPointCard', node)} style={{ margin: '5px' }}>
                  <Plus className='feather' /> {t("Data Point")}
                </Button>
                <Button variant="outline-primary" size="sm" onClick={() => addChild('DataPointCardLastUsageCycle', node)} style={{ margin: '5px' }}>
                  <Plus className='feather' /> {t("Usage Cycle")}
                </Button>
                <Button variant="outline-danger" size="sm" onClick={() => window.confirm(t("Are you sure?")) && removeChildFromNode(parent, index)} style={{ margin: '5px' }}>
                  <X className='feather' />  {t("Row")}
                </Button>
              </div>
            </Row>
          );
        case 'Col':
          return (
            <div style={{ backgroundColor: palette["gray-100"], padding: "20px", margin: "0px" }}>
              <Row>
                <div className="h6">{t("Column")}</div>
              </Row>
              <Row>
                <Col>
                  <Form.Label>{t("Size")}</Form.Label>
                  <Form.Select
                    value={node.size || 6}
                    onChange={(e) => updateColumnSize(parent, index, parseInt(e.target.value))}
                    size="sm"
                  >
                    {columnSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Group className="mb-2">
                    <Form.Label>{t("Title")}</Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      placeholder="Enter title"
                      value={node.title || ""}
                      onChange={(e) => {
                        node.title = e.target.value;
                        setStructure({ ...structure });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {node.children?.map((child, childIndex) => (
                <div key={childIndex}>{renderComponent(child, node, childIndex)}</div>
              ))}
              <div style={{ marginTop: '10px' }}>
                <Button variant="outline-primary" size="sm" onClick={() => addChild('Row', node)} style={{ margin: '5px' }}>
                  <Plus className='feather' /> {t("Row")}
                </Button>
                <Button variant="outline-primary" size="sm" onClick={() => addChild('Divider', node)} style={{ margin: '5px' }}>
                  <Plus className='feather' /> {t("Divider")}
                </Button>
                <Button variant="outline-danger" size="sm" onClick={() => removeChildFromNode(parent, index)} style={{ margin: '5px' }}>
                  <X className='feather' /> {t("Column")}
                </Button>
              </div>
            </div>
          );
        case 'DataPointCard':
          return (
            <Card >
              <Card.Body>
                <Form.Group>
                  <Form.Label>{t("IoT Data Tile")}</Form.Label>
                  <Form.Select
                    name="state"
                    value={node.plc_node_id}
                    onChange={(e) => {
                      node.plc_node_id = e.target.value;
                      setStructure({ ...structure });
                    }}
                    className="mt-n1"
                  >
                    {configData?.items?.map((item) => (
                      <option key={item.id} value={item?.log_type?.plc_node_id}>
                        {item?.log_type?.technical_name || "-"}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Check
                    checked={node.alwaysShow}
                    className="my-2"
                    onChange={(e) => {
                      node.alwaysShow = e.target.checked;
                      setStructure({ ...structure });
                    }}
                    label={t("Show even if 0")}
                    type="checkbox" />

                  <Form.Control
                    value={node.description}
                    onChange={(e) => {
                      node.description = e.target.value;
                      setStructure({ ...structure });
                    }}
                    rows="5"
                    as="textarea"
                    placeholder={t("Description")}
                  />

                </Form.Group>
                <Button className='mt-2' variant="outline-danger" size="sm" onClick={() => removeChildFromNode(parent, index)}>
                  <X className='feather' /> {t("Tile")}
                </Button>
              </Card.Body>
            </Card>
          );
        case 'DataPointCardLastUsageCycle':
          return (
            <Card >
              <Card.Body>
                <Form.Group>
                  <Form.Label>{t("Last Usage Cycle")}</Form.Label>
                </Form.Group>
                <Button className='mt-2' variant="outline-danger" size="sm" onClick={() => removeChildFromNode(parent, index)}>
                  <X className='feather' /> {t("Tile")}
                </Button>
              </Card.Body>
            </Card>
          );

        case 'Divider':
          return (
            <div>
              <hr />
              <Button variant="outline-danger" size="sm" onClick={() => removeChildFromNode(parent, index)} style={{ margin: '10px' }}>
                <X className='feather' /> {t("Divider")}
              </Button>
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="digital-twin-tabs">
          {structure.tabs.map((tab) => (
            <Tab
              eventKey={tab.eventKey}
              key={tab.eventKey}
              title={
                <span>
                  {tab.iconUrl && (
                    <img src={tab.iconUrl} alt="icon" style={{ width: 20, height: 20, marginRight: 5 }} />
                  )}
                  {tab.title}
                  <X
                    className="feather"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeTab(tab.eventKey);
                    }}
                    style={{ color: "red", cursor: "pointer", marginLeft: 5 }}
                  />
                </span>
              }
            >
              <div className="mt-3 mb-3" style={{ backgroundColor: palette["gray-300"], padding: "20px", margin: "0px" }}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Tab Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter icon URL"
                        value={tab.title}
                        onChange={(e) => updateTabProperty(tab.eventKey, 'title', e.target.value)}
                      />
                    </Form.Group>

                  </Col>
                  {/* <Col>
                    <Form.Group>
                      <Form.Label>Tab Icon URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter icon URL"
                        value={tab.iconUrl}
                        onChange={(e) => updateTabProperty(tab.eventKey, 'iconUrl', e.target.value)}
                      />
                    </Form.Group>
                  </Col> */}

                  {UserDataManager.getInstance().isAdmin() && <Col>
                    <Form.Group >
                      <Form.Label>Wallpaper URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter background image URL"
                        value={tab.wallpaper}
                        onChange={(e) => updateTabProperty(tab.eventKey, 'wallpaper', e.target.value)}
                      />
                    </Form.Group>
                  </Col>}
                </Row>

              </div>
              <div
                // className="p-3"
                style={{
                  position: 'relative',
                  minHeight: '400px',
                  backgroundImage: tab.backgroundImageUrl ? `url(${tab.backgroundImageUrl})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {tab.children.map((child, index) => (
                  <div key={index}>{renderComponent(child, tab, index)}</div>
                ))}
              </div>

            </Tab>
          ))}
          <Tab
            title={
              <Button variant="link" onClick={addTab} style={{ textDecoration: 'none', padding: '0px' }}>
                <Plus className="feather" /> {("Tab")}
              </Button>
            }
          />
        </Tabs>

        <div className="mb-3 text-center">
          <Button variant="primary"  onClick={runDesigner}>
            {t("Convert to Digital Twin")}
          </Button>
        </div>

        <Row>
          <Col md="12">
            {jsonOutput && <DigitalTwinCard simulatedJsonData={jsonOutput} slim />}
            {jsonOutput && <UnusedDataPoints updatedTwinJSON={jsonOutput} />}
          </Col>
        </Row>

      </div>
    );
  };

  const UnusedDataPoints = ({ updatedTwinJSON }) => {

    const twinStructurePLCNodeIds = extractPLCNodeIds(updatedTwinJSON);

    // console.log("--->", twinStructurePLCNodeIds)

    // configData?.items?.forEach(item => {
    //   console.log("--->", item.log_type.plc_node_id)
    // })

    let missingDataPoints = configData?.items?.filter(dataPoint =>
      // !twinStructurePLCNodeIds.includes(dataPoint.log_type.plc_node_id)
      !twinStructurePLCNodeIds.includes(dataPoint?.log_type?.plc_node_id)
    );

    // console.log(`Found ${twinStructurePLCNodeIds.length} plc_node_ids. Missing data points ${missingDataPoints.length}`)


    return (<Card>
      <Card.Body>
        <div className="h4 mb-3">{t("Unused data points")}</div>
        <ul>
          {missingDataPoints?.length > 0
            ? missingDataPoints?.map((item, index) => {

              const escapedPlcNodeId = item?.log_type?.plc_node_id?.replace(/\\/g, '\\\\').replace(/"/g, '\\"');

              return (
                <li key={index}>
                  <strong>{t(item?.log_type?.technical_name || item?.log_type?.display_name)}</strong>: {t(item?.log_type?.plc_node_id)} <Button onClick={() => navigator.clipboard.writeText(escapedPlcNodeId)} size="sm"
                    variant="outline-primary">
                    <Clipboard className="feather" /></Button>
                </li>)
            }
            )
            : <li key={"index"}>{t("All used")}</li>
          }
        </ul>
      </Card.Body>
    </Card>)
  }

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{t("Digital Twin Designer (Beta)")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <DigitalTwinDesigner configData={configData} onJsonChange={setJsonOutput} />
        </Row>
        <Row>
          <Form onSubmit={handleUpdate}>
            <Row>
              {/* <Col md={"0"}>
                <Row>
                  <Col xs="auto" className="ms-auto text-end">
                    <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={() => setTwinJSON(JSON.stringify(DefaultTwinStructure, null, 2))}>
                      {t("Use default")}
                    </Button>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Formatted")}</Form.Label>

                  <Form.Control
                    rows="30"
                    as="textarea"
                    onChange={e => setTwinJSON(e.target.value)}
                    value={twinJSON}
                    style={{ display: 'none' }} // Hide the textarea
                  />
                  <ReactJson
                    src={parsedJSON}
                    collapsed
                    onEdit={edit => setTwinJSON(JSON.stringify(edit.updated_src, null, 2))}
                    onAdd={add => setTwinJSON(JSON.stringify(add.updated_src, null, 2))}
                    onDelete={del => setTwinJSON(JSON.stringify(del.updated_src, null, 2))}
                    theme="monokai"
                    style={{ padding: '20px', backgroundColor: '#2c2c2c', borderRadius: '5px' }}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label >{t("Raw")}</Form.Label>
                  <Form.Control
                    rows="24"
                    as="textarea"
                    onChange={e => setTwinJSON(e.target.value)}
                    value={twinJSON}
                  />
                </Form.Group>
              </Col> */}

              {/* <Col md="12">
                <DigitalTwinCard simulatedJsonData={parsedJSON} slim />

                {parsedJSON && <UnusedDataPoints updatedTwinJSON={parsedJSON} />}
              </Col> */}
            </Row>

            <br />
            <br />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={handleClose}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Apply")}
                </Button>
              </Col>
            </Row>
          </Form >
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default SingleTwinDesignerModal;