import {
  Badge,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  ProgressBar,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  Edit,
  Eye,
  HelpCircle,
  Maximize2,
  Plus,
  Trash,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import PowerConsumptionChart from "../Charts/PowerConsumptionChart";
import axios from "axios";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import UserDataManager from "../../Helper/UserDataManager";
import { format, formatDistanceToNow, formatDuration, intervalToDuration, parseISO } from "date-fns";
import SingleMaintenanceEventModal from "../../Maintenance/SingleMaintenanceEventModal";
import AssetHealthCheckSection from "./AssetHealthCheckSection";
import AssetUsageDetails from "../AssetUsageDetails";
import { Line } from "react-chartjs-2";
import usePalette from "../../../hooks/usePalette";
import OPCUA_Logo from "../../../assets/img/logos/opc_ua.png";
import mqtt_Logo from "../../../assets/img/logos/mqtt.png";
import beckhoff_Logo from "../../../assets/img/logos/beckhoff.png";
import modbus from "../../../assets/img/logos/modbus.png";
import AssetUsageSection from "./AssetUsageSection";

const ProductivitySection = ({ assetData, productionContentLoaded }) => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [assetProduction, setAssetProduction] = useState();
  // const [AssetLogSOLLISTArray, setAssetLogSOLLISTArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const palette = usePalette();

  useEffect(() => {

    if (productionContentLoaded) {

      setIsLoading(true)

      axios
        .get("/webservice/assets/" + assetData?.id + "/production")
        .then(function (response) {
          // handle success
          console.log(response.status, "/production");
          setAssetProduction(response.data)
          // console.log("----->",response.data)
          // setAssetLogSOLLISTArray(response.data?.usage_section?.planned_actual_usages?.reverse());
          setIsLoading(false)
        })
        .catch(function (error) {
          // handle error
          setIsLoading(false)
          console.log(error);
        });
    }

  }, [productionContentLoaded]);



  function getOperatingTimes(stateString) {
    if (assetProduction?.oee_section?.state_periods === null || assetProduction?.oee_section?.state_periods.length === 0) {
      return "-"
    } else {
      let secondsForState = assetProduction?.oee_section?.state_periods?.find(element => element.state_name === stateString)?.current_month_seconds
      return secondsForState === undefined ? "-" : formatDuration(intervalToDuration({ start: 0, end: secondsForState * 1000 }), { format: ['days', 'hours', 'minutes'], locale: i18n.language === "de" ? de : null, delimiter: ", " })
    }
  }



  const AssetOEESection = () => {
    return (<Row>

      <Col md="12">
        <Row>
          {/* OEE Tile */}
          <Col md={3} xs="6" className="d-flex">
            <Card className="flex-fill">
              <Card.Body>
                <Row>
                  <Col className="mb-2">
                    <h5 className="card-title">{t("Overall Equipment Effectiveness (OEE)")}</h5>
                  </Col>
                  <Col xs="auto" className="ms-auto text-end">
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Overall Equipment Effectiveness (OEE)")}</Popover.Header>
                          <Popover.Body>
                            <h4>{t("OEE")}: {assetProduction?.oee_section?.last_oee?.oee?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'percent',
                              maximumFractionDigits: 1,
                            })}</h4>
                            {t("Yesterday")}: {assetProduction?.oee_section?.last_oee === undefined ? "-" : assetProduction?.oee_section?.last_oee?.interval_start === undefined ? "n/a" : format(parseISO(assetProduction?.oee_section?.last_oee?.interval_start), 'eee, d MMMM', { locale: i18n.language === "de" ? de : null }) || "0"}<br />
                            <br />
                            <hr />
                            <h4>{t("OEE-Factors")}</h4>
                            {t("Availability")}: <strong>{assetProduction?.oee_section?.last_oee?.availability?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'percent',
                              maximumFractionDigits: 1,
                            })}</strong><br />
                            {t("Measures machine uptime versus downtime, taking into account events that stop production for an appreciable length of time.")}<br /><br />

                            {t("Performance")}: <strong>{assetProduction?.oee_section?.last_oee?.performance?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'percent',
                              maximumFractionDigits: 1,
                            })}</strong><br />
                            {t("Measures the speed at which products are manufactured against the ideal speed.")}<br /><br />

                            {t("Quality")}: <strong>{assetProduction?.oee_section?.last_oee?.quality?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'percent',
                              maximumFractionDigits: 1,
                            })}</strong><br />
                            {t("Measures the quality of the parts produced, considering the number of good parts versus the total parts produced.")}<br /><br />


                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <HelpCircle className="feather" />

                    </OverlayTrigger>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-1 mb-4">{assetProduction?.oee_section?.last_oee === undefined ? "0" : assetProduction?.oee_section?.last_oee?.oee === null ? "0" : assetProduction?.oee_section?.last_oee?.oee?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'percent', maximumFractionDigits: 0
                }) || "n/a"}</span>
                <div className="mb-0">
                  <span className="text-muted">{assetProduction?.oee_section?.last_oee === undefined ? "n/a" : assetProduction?.oee_section?.last_oee?.interval_start === undefined ? "n/a" : format(parseISO(assetProduction?.oee_section?.last_oee?.interval_start), 'eee, d MMMM', { locale: i18n.language === "de" ? de : null }) || "-"}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {
            assetProduction?.oee_section?.state_periods?.map(entry => {
              return (
                <Col key={entry?.state_name} md={3} xs="6" className="d-flex">
                  <Card className="flex-fill">
                    <Card.Body>
                      <Row>
                        <Col className="mb-2">
                          <h5 className="card-title">{t(entry?.state_name)}</h5>
                        </Col>
                        {/* <Col xs="auto" className="ms-auto text-end">
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Popover>
                                <Popover.Header as='h3'>{t(entry?.state_name)} {t("Current month")}</Popover.Header>
                                <Popover.Body>
                                  {t(`${entry?.state_name}_description`)}<br /><br />
                                  {
                                    assetProduction?.oee_section?.state_periods?.map(element => {
                                      return <ul key={element.state_name}> <strong>{t(element.state_name)}:</strong><br />{getOperatingTimes(element.state_name)} ({(element.current_month_seconds / assetProduction?.oee_section?.state_periods.reduce((a, v) => a = a + v.current_month_seconds, 0))?.toLocaleString(i18n.language === "de" ? de : "en", {
                                        style: 'percent',
                                        maximumFractionDigits: 1,
                                      })})</ul>
                                    })
                                  }
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <HelpCircle className="feather" />
                          </OverlayTrigger>
                        </Col> */}
                      </Row>
                      <span className="h1 d-inline-block mt-1 mb-4">
                        {(entry?.percent_of_total_time)?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'percent',
                          maximumFractionDigits: 1,
                        }) || "-"}
                      </span>
                      <div className="mb-0">
                        {/* <Badge bg="" className="badge-soft-success me-2">{badgeValue}</Badge> */}
                        <span className="text-muted">{t("Current month")}</span>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })
          }
        </Row>
      </Col>

    </Row>)
  }

  const ChartManufacturerCustom = () => {
    const palette = usePalette();

    let dataset = assetProduction?.manufacturer_section?.custom_chart?.graphs?.map(item => {
      return {
        label: t(item?.name),
        fill: true,
        tension: false,
        backgroundColor: "transparent",
        borderColor: palette[item?.color],
        data: item?.y_values?.map(value => { return value })
      }
    })

    const data = {
      labels: assetProduction?.manufacturer_section?.custom_chart?.graphs[0]?.x_values?.map(item => { return format(parseISO(item), 'HH:mm', { locale: i18n.language === "de" ? de : null }) }),
      datasets: dataset
    };

    const options = {
      animation: false,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        horizontalAlign: 'center',
        itemMargin: {
          horizontal: 5,
          vertical: 0
        },
        onItemClick: {
          toggleDataSeries: true
        },
      },
      tooltips: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, });
            return label
          }
        }

      },
      scales: {
        xAxes: [
          {
            reverse: true,
            gridLines: {
              color: "rgba(0,0,0,0.05)",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            borderDash: [5, 5],
            gridLines: {
              color: "rgba(0,0,0,0)",
              fontColor: "#fff",
            },
            ticks: {
              // stepSize: 1000,
              callback: function (value, index, ticks) {
                return value.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, });
              }
            },
          },
        ],
      },
    };

    return (
      <Card className="flex-fill w-100">
        <Card.Header>
          {/* <Card.Title tag="h5">{t("Asset Usage (Monthly)")}</Card.Title> */}
          {/* <h6 className="card-subtitle text-muted">
          A line chart is a way of plotting data points on a line.
        </h6> */}
          <Row>
            <Col>
              <Card.Title tag="h5">{t(assetProduction?.manufacturer_section?.custom_chart?.header)} (in {assetProduction?.manufacturer_section?.custom_chart?.unit})</Card.Title>
            </Col>
            {/* <Col xs="auto" className="ms-auto text-end">
              <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate("/assets/" + id + "/usage")}>{t("More usage details")}</Button>
            </Col> */}
          </Row>
        </Card.Header>
        <Card.Body>
          <div className="chart">
            <Line data={data} options={options} />
          </div>
        </Card.Body>
      </Card>
    );
  }


  const AssetManufacturerSection = () => {
    return (<>
      <Row>
        <Col md="9" className="d-flex">
          <ChartManufacturerCustom />
        </Col>
        <Col md="3" >
          <Row>
            {/* connection_protocol Tile */}
            <Col md="12" xs="6" className="d-flex">
              <Card className="flex-fill">
                <Card.Body className=" py-4">
                  <Row>
                    <Col className="mb-2">
                      <h5 className="card-title">{t("connection_protocol")}</h5>
                    </Col>
                  </Row>
                  <span className="h1 d-inline-block mt-1 mb-4">
                    {assetProduction?.manufacturer_section?.connection_protocol === "opc_ua" && <img
                      src={OPCUA_Logo}
                      alt="avatar"
                      className="me-3"
                      width="auto"
                      height={30}
                    />}
                    {assetProduction?.manufacturer_section?.connection_protocol === "mqtt" && <img
                      src={mqtt_Logo}
                      alt="avatar"
                      className="me-3"
                      width="auto"
                      height={30}
                    />}
                    {assetProduction?.manufacturer_section?.connection_protocol === "ads" && <img
                      src={beckhoff_Logo}
                      alt="avatar"
                      className="me-3"
                      width="auto"
                      height={30}
                    />}
                    {assetProduction?.manufacturer_section?.connection_protocol === "modbus" && <img
                      src={modbus}
                      alt="avatar"
                      className="me-3"
                      width="auto"
                      height={30}
                    />}
                    {/* <a>{gatewayData.last_handshake?.asset_connected === true ? t("Connected") : t("Not connected")}</a> */}

                  </span>
                  {/* <div className="mb-0">
                  <span className="text-muted">{assetData?.manufacturer_section?.connection_protocol?.toUpperCase() || "-"}</span>
                </div> */}
                </Card.Body>
              </Card>
            </Col>

            <Col md="12" xs="6" className="d-flex">
              <Card className="flex-fill">
                <Card.Body className=" py-4">
                  <Row>
                    <Col className="mb-2">
                      <h5 className="card-title">{t("next_service")}</h5>
                    </Col>
                  </Row>

                  <span className="h1 d-inline-block mt-1 mb-4">{assetProduction?.manufacturer_section?.next_service?.percentage_since_last_service == null ? "-" : (assetProduction?.manufacturer_section?.next_service?.percentage_since_last_service).toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 2, })}</span>
                  <ProgressBar
                    className="mb-3"
                    variant="primary"
                    striped
                    animated
                    now={assetProduction?.manufacturer_section?.next_service === undefined ? "-" : assetProduction?.manufacturer_section?.next_service?.percentage_since_last_service * 100}
                    label=""
                  />

                  <div className="mb-0">
                    <Badge bg="" className="badge-soft-success me-2">
                      {assetProduction?.manufacturer_section?.next_service?.usages_until_next_service === undefined ? "-" : (assetProduction?.manufacturer_section?.next_service?.usages_until_next_service).toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'decimal',
                        maximumFractionDigits: 0,
                      })}
                    </Badge>
                    <span className="text-muted">{t("usages till next service")}</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {assetProduction?.manufacturer_section?.custom_value_1 && <CustomValueTile value={assetProduction?.manufacturer_section?.custom_value_1} />}
        {assetProduction?.manufacturer_section?.custom_value_2 && <CustomValueTile value={assetProduction?.manufacturer_section?.custom_value_2} />}
        {assetProduction?.manufacturer_section?.custom_value_3 && <CustomValueTile value={assetProduction?.manufacturer_section?.custom_value_3} />}
        {assetProduction?.manufacturer_section?.custom_value_4 && <CustomValueTile value={assetProduction?.manufacturer_section?.custom_value_4} />}
      </Row>
    </>
    )
  }

  const CustomValueTile = ({ value }) => {
    return (
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{value?.name || "-"}</h5>
              </Col>
            </Row>
            {
              value?.unit === "ms"
                ? <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 }) || "-"} ms</span>
                : value?.unit === "C"
                  ? <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })} °C</span>
                  : value?.unit === "state"
                    ? <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })}</span>
                    : value?.unit === "s"
                      ? <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })}</span>
                      : value?.unit === "#"
                        ? <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) || "-"}</span>
                        : value?.unit === "-"
                          ? <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) || "-"}</span>
                          : value?.unit === "Info Light"
                            ? <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) || "-"} {value?.unit || ""}</span>
                            : <span className="h1 d-inline-block mt-1 mb-4">{value?.value?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) || "-"} {value?.unit || ""}</span>
            }
            {/* <div className="mb-0">
                  <span className="text-muted">custom_value_1</span>
                </div> */}
          </Card.Body>
        </Card>
      </Col>
    )
  }

  return (<div style={{ background: palette["gray-200"], padding: "20px" }}>
    {isMobile && <Row className="mt-3 mb-4">
      <Col>
        <div className="h3">{t("Production")}</div>
      </Col>
      {/* <Col xs="auto" className="ms-auto text-end mt-n1">
        {localStorage.getItem("tenant_id") !== "tWMPWtl" && <Button onClick={() => navigate("/assets/" + assetData?.id + "/insights")} variant="outline-primary" size="sm" className="shadow-sm me-1">
          <Eye className="feather" /> {isMobile ? "" : t("Insights")}
        </Button>}
      </Col> */}
    </Row>}
    {isLoading
      ? <LoadingSpinner withStandardText />
      : <div>
        <AssetUsageDetails assetData={assetData} show={productionContentLoaded} />
        {assetData?.usage_enabled && <AssetUsageSection assetData={assetData} assetProduction={assetProduction}/>}
        {assetProduction?.oee_section && <AssetOEESection />}
        {assetProduction?.manufacturer_section && assetProduction?.manufacturer_section?.connection_protocol && <AssetManufacturerSection />}
      </div>
    }

  </div>)
}

export default ProductivitySection;
