import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";
import { getIAMBaseURL } from "../../config";
import BaseURLsOptions from "../Helper/BaseURLsOptions";

const AddUserModal = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [role, setRole] = useState("regular");; // default value!
  const [gatewayId, setGatewayId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [phoneNumber_, setPhoneNumber] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("en");
  const [sendCredentialsEmail, setSendCredentialsEmail] = useState(false);
  const [tenantId, setTenantId] = useState();
  const [webappBaseUrl, setWebappBaseUrl] = useState();

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .post(getIAMBaseURL() + "/users/", {
        "email": email,
        "first_name": firstName,
        "last_name": lastName,
        "role": role,
        "phone_number": phoneNumber_,
        "preferred_language": preferredLanguage,
        "position": position,
        "password": password,
        // "client_id": clientId.length > 0 ? clientId : null,
        "iot_gateway_id": gatewayId === "" ? null : gatewayId,
        "send_credentials_email": sendCredentialsEmail,
        "tenant_id": tenantId,
        "webapp_base_url": webappBaseUrl
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/users");
        toggle(false);

        notyf.open({
          type: "success",
          message: t("New item \"") + firstName + " " + lastName + t("\" has been added."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const [UserRolesArray, setUserRolesArray] = useState([]);
  const [tenants, setTenants] = useState([]);

  useEffect(() => {

    axios
      .get(getIAMBaseURL() + "/tenants/")
      .then(function (response) {
        // handle success
        setTenants(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(getIAMBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "/openapi.json");
        setUserRolesArray(response.data["components"]["schemas"]["UserRole"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });


  }, []);


  const loadRoles = () => {
    return UserRolesArray.map((roleItem) => {
      return (
        <option key={roleItem} value={roleItem} selected={roleItem === role} >{t(roleItem).toUpperCase()}</option>
      );
    });
  };

  const [GatewayArray, setGatewayArray] = useState([]);

  useEffect(() => {
    axios
      .get("/iot_gateways/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/gateways");
        setGatewayArray(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const loadGateways = () => {
    return GatewayArray.map((gateway) => {
      return (
        <option key={gateway.id} value={gateway.id}>{gateway.serial}</option>
      );
    });
  };

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm me-1"
      >
        <Plus className="feather" /> {t("New")}
      </Button>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create user")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("First name")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    placeholder={t("First name")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Last name")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder={t("Last name")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                {/* <Form.Group className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Control onChange={handleChangeRole} placeholder="Role" />
              </Form.Group> */}
                <Form.Group className="mb-3">
                  <Form.Label>{t("Role")}</Form.Label>
                  <Form.Select name="state" required onChange={(e) => setRole(e.target.value)}>
                    <option disabled value="">{t("-- Select --")}</option>
                    {loadRoles()}
                  </Form.Select>
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Client")}</Form.Label>
                        <Form.Select 
                        name="state" 
                        onChange={(e) => setClientId(e.target.value)}>
                          <option value="">{t("-- Select (optionally) --")}</option>
                          {loadClients()}
                        </Form.Select>
                      </Form.Group>
                    </Col> */}
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Email")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder={t("Email")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Password")}</Form.Label>
                  <Form.Control
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    type="password"
                    placeholder={t("Password")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Tenant")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => setTenantId(e.target.value)} required>
                    <option value="">{t("-- Select --")}</option>
                    {tenants?.map((item) => {
                      return (
                        <option key={item?.id} value={item?.id}>{item?.name?.toUpperCase()}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Position")}</Form.Label>
                  <Form.Control onChange={(e) => setPosition(e.target.value)} placeholder={t("Position")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Phone Number")}</Form.Label>
                  <Form.Control onChange={(e) => setPhoneNumber(e.target.value)} placeholder={t("Phone Number")} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Language")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => setPreferredLanguage(e.target.value)}>
                    <option value="en">{t("-- Select --")}</option>
                    <option value="de">{t("German")}</option>
                    <option value="en">{t("English")}</option>
                    <option value="us">{t("English (US)")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {
              role === "device" &&
              <Row>
                <Col md={12}>
                  <Form.Label>{t("cap-on IoT-Gateway")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => setGatewayId(e.target.value)}>
                    <option />
                    {loadGateways()}
                  </Form.Select>
                </Col>
              </Row>
            }
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Base URL")}</Form.Label>
                  <Form.Select
                    onChange={(e) => setWebappBaseUrl(e.target.value)}
                  >
                    <option value={null}>{t("Default")}</option>
                    <BaseURLsOptions />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Check
                  checked={sendCredentialsEmail}
                  className="mt-1"
                  onChange={e => setSendCredentialsEmail(!sendCredentialsEmail)}
                  label={t("Send welcome email with credentials")}
                  type="checkbox" />
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUserModal;
